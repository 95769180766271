<template>
    <div class="about-us">
        <index-navbar/>
        <card class="custom-card" :title="$i18n.locale=='fa' ? 'درباره ما' : 'About us'" >
            <template #default>

                <p v-html="$i18n.locale=='fa' ? $store.state.SiteData.about_us_text_f : $store.state.SiteData.about_us_text"></p>
                <vue-plyr :options="options">
                    <video
                        controls
                        crossorigin
                        playsinline
                        data-poster="poster.jpg"
                    >
                        <source
                        size="720"
                        src="/static/vids/nimcatvid.mp4"
                        type="video/mp4"
                        />
                    </video>
                </vue-plyr>
                
                <menu>
                    <a class="btn btn-primary" href="/">{{ $t("message.Return") }}</a>
                </menu>
            </template>
        </card>
    </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import Card from "@/components/Section/Card.vue"
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

export default {
    data() {
        return {
            options: {}
       }
    },

    components: {
        Card,
        VuePlyr, 
        IndexNavbar
    }
}
</script>

<style scoped>
.custom-card {
    top: 15vh;
    z-index: -1;
}

</style>