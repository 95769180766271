// Update the count down every 1 second
function iconTrueFalse(tf) {
  if (tf==true) {
    return "fa fa-check-circle green"
  }
  else {
      return "fa fa-times-circle red"
  }

}

function formatDate(d) {
  if (d) {
    let sdate = d.toLocaleString()
    return this.$moment(Date.parse(sdate)).format(
        "YYYY-MMM-DD HH:mm"
    );
  } else {
    return ""
  }
}

export { iconTrueFalse, formatDate };