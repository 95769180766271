import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
    state() {
        return {
        about_us_text: "About us",
        siteId: "id", 
        about_us_text_f: "about_us_text_f",
        first_page_text: "first_page_tex",
        first_page_text_f: "first_page_text_f",
        banner_url: "",        

        };
    },
    actions,
    mutations,
    getters
}