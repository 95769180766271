import { apiService } from '../api.service.js';


export default {
    login(context) {
        let endpoint = 'api/user/'
        apiService(endpoint).then((data) => {
            let response = []
            response = JSON.parse(JSON.stringify(data))[0];
            if (response != undefined) {
                context.commit('setAuthUser', {
                    userIsAuth: true,
                    id: response.id,
                    first_name: response.first_name,
                    last_name: response.last_name,
                    first_name_f: response.first_name_f,
                    last_name_f: response.last_name_f,
                    is_teacher: response.is_teacher,
                    free_session_used: response.free_session_used,
                })
            }
            else {
                console.log(data.detail)
            }
            }).catch(e =>{
                console.log(e)
        });
    },

}