<template>
    <div class="teacher">
        <index-navbar />
        <section class="relative py-20 mt-24">
            <component :is="currentComponent" v-bind="currentProps" @selectPlan ="activatePlan"></component>
        </section>
        <footer-component id="footer-1" />
        <base-dialog v-if="anonymousModal" :title="$t('message.login')">
            <template #default>
                <p>{{ $t("message.loginFirst") }}</p>
            </template>
            <template #actions>
                <button class="btn btn-sm btn-info"
                onclick="location.href='/accounts/login/'">
                 {{ $t("message.login") }}
                </button>
                <button class="btn btn-sm btn-danger" @click="close()">
                {{ $t("message.close") }}</button>
            </template>
        </base-dialog>
    </div>
</template>

<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/FooterSmall.vue";
import TeacherIntro from "./TeacherIntro.vue";
import BuyCourse from "@/views/Sessions/BuyCourse.vue";
import BaseDialog from '@/components/Modal/BaseDialog.vue'

export default {
    name: "TeacherProfile",

    data() {
        return {
            selected_plan: null,
            anonymousModal: false,
        };
    },

    provide() {
        return {
            selectPlan: this.activatePlan,
        };
    },

    methods: {
        activatePlan(plan) {
            if (this.$store.state.auth.userIsAuth) { 
                this.selected_plan = plan;
            }
            else {
                this.anonymousModal = true 
            }
        },
        close() {
            this.anonymousModal = false
        }
    },

    computed: {
        currentComponent: function () {
            if (this.selected_plan != null) {
                return "buy-course";
            } else {
                return "teacher-intro";
            }
        },
        currentProps: function () {
            if (this.selected_plan == "free_demo") {
                let free_props = {id: 0, num_of_sessions: 1, discount: "0.00" }
                return { selected_plan: free_props };
            }
            else if (this.selected_plan != null) {
                return { selected_plan: this.selected_plan };
            } else {
                return {};
            }
        },
    },

    components: {
        IndexNavbar,
        FooterComponent,
        TeacherIntro,
        BuyCourse,
        BaseDialog
    },
};
</script>

<style>
.alert-fix {
    width: 70%;
    border-radius: 5px;
    top: 5px;
    left: 20px;
}
.full-h {
    min-height: 100vh;
}

.sold-event {
    cursor: no-drop;
}
.waiting-event {
    cursor: no-drop;
}
.availble-event {
    cursor: pointer;
}
</style>