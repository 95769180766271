 <template>
     
    <div class="px-6">
        <a :href="pageLink(tid)">
            <img
                :alt="alt"
                v-lazy="src"
                class="
                    shadow-lg
                    rounded-full
                    mx-auto
                    max-w-120-px
                "
            />
            <div class="pt-6 text-center">
                <h5 class="text-xl font-bold">{{ name }}</h5>
                <p
                    class="
                        mt-1
                        text-sm text-blueGray-400
                        uppercase
                        font-semibold
                    "
                >
                    {{ calss_name }}
                </p>
                
            </div>
        </a>
    </div>
 </template>


<script>
export default {
    data() {
        return {
            
        };
    },
    methods: {
        pageLink(id) {
            return "teacher-" + id
        }
    },

    props: [
        "tid",
        "alt",
        "src",
        "name",
        "calss_name",
    ],
};
</script>
 