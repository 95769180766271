<template>
  <footer class="block py-4">
    <div class="container mx-auto px-4">
      <hr class="mb-4 border-b-1 border-blueGray-200" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4">
          
        </div>
        <div class="w-full md:w-8/12 px-4">
          <ul class="flex flex-wrap list-none md:justify-end justify-center">
            <li>
              <a
                href="/"
                class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3"
              >
                {{ $t("message.Nimcat") }}
              </a>
            </li>
            <li>
              <button
                type="a"
                class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3"
                @click="openAbout"
              >
                {{ $t("message.About") }}
              </button>
            </li>
            
          </ul>
        </div>
      </div>
    </div>

    <base-dialog v-if="UserAboutUs" :title="$t('message.About')">
          <template #default>
              <p v-html="$i18n.locale=='fa' ? siteData.about_us_text_f : siteData.about_us_text"></p>
          </template>
          <template #actions>
              <div class="btn-group">
                  <button  onclick='location.href="mailto:info@nimcatonlineschool.com"' 
                  class="btn btn-sm btn-info">{{ $t('message.sendEmail') }}</button>
                  <button @click="closeAbout" class="btn btn-danger btn-success">{{ $t('message.close') }}</button>
              </div>
          </template>
      </base-dialog> 
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
      UserAboutUs: false,
    };
  },

  methods: {
    closeAbout: function()  {
      if (this.UserAboutUs == true) {
        this.UserAboutUs = false
      } 
    },
    openAbout: function() {
      // if (this.UserAboutUs == false) {
      //   this.UserAboutUs = true
      // } else {
      //   this.UserAboutUs = false
      // }
      window.location.href="about-us"
    },
  }
};
</script>
