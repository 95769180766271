<template>
<div>
  <div id="alert-area" style="position:absolute;" v-if="alertClass != ''"
    class="alert w-full" :class="alertClass" role="alert">
    {{ alertContent }}
  </div>
<form>
      <div class="row">
        <div class="form-cl col-lg-6">
        <label for="user-name">{{ $t("message.name") }}</label>
        <input id="user-name" name="user-name" type="text" v-model="userName" />
        </div>
        <div class="form-cl col-lg-6">
        <label for="age">{{ $t("message.age") }}</label>
        <input id="age" name="age" type="number" v-model="userAge"  />
        </div>
      </div>
      <div class="row">
        <div class="form-cl col-lg-6">
        <label for="email">{{ $t("message.email") }}</label>
        <input id="email" name="email" type="email" v-model="userEmail" />
        </div>
        <div class="form-cl col-lg-6">
        <label for="referrer">{{ $t("message.hearaboutus") }}</label>
        <select id="referrer" name="referrer" v-model="howKnow">
            <option value="google">{{ $t("message.google") }}</option>
            <option value="wom">{{ $t("message.friends") }}</option>
            <option value="instagram">{{ $t("message.instagram") }}</option>
        </select>
        </div>
      </div>

      <div class="row">
        <div class="form-cl col-lg-6">
        <label for="phone-number">{{ $t("message.PhoneNumber") }}</label>
        <input id="phone-number" name="phone-number" type="text" v-model="phoneNumber" />
        </div>
        <div class="form-cl col-lg-6">
        <label for="country">{{ $t("message.country") }}</label>
        <input id="country" name="age" type="text" v-model="country"  />
        </div>
      </div>
      
      <div class="row">
        <div class="form-cl col-lg-6">
        <label for="referrer">{{ $t("message.englishlevel") }}</label>
        <select id="referrer" name="referrer" v-model="englishLevel">
            <option value="No English knowledge">{{ $t("message.noenglish") }}</option>
            <option value="Elementary">{{ $t("message.elementary") }}</option>
            <option value="Intermediate">{{ $t("message.intermediate") }}</option>
            <option value="Advanced">{{ $t("message.advanced") }}</option>
        </select>
        </div>
        <div class="form-cl col-lg-6">
          <label for="notes">{{ $t("message.Notes") }}</label>
          <textarea class="form-control" row="2" id="notes" name="notes" v-model="notes" />
        </div>
      </div>
      
    <div class="form-cl">
      <h2>{{ $t("message.interestedcourses") }}</h2>
      <div class="row">
        <div class="col-lg-3">
            <input id="interest-news" name="interest" value="Farsi" type="checkbox" v-model="interestedCourses" />
            <label for="interest-news">{{ $t("message.farsi") }}</label>
        </div>
        <div class="col-lg-3">
            <input id="interest-tutorials" name="interest" value="Programming" type="checkbox" v-model="interestedCourses" />
            <label for="interest-tutorials">{{ $t("message.programming") }}</label>
        </div>
        <div class="col-lg-3">
            <input id="interest-nothing" name="interest" value="Music" type="checkbox" v-model="interestedCourses" />
            <label for="interest-nothing">{{ $t("message.music") }}</label>
        </div>
        <div class="col-lg-3">
            <input id="interest-nothing" name="interest" value="Others" type="checkbox" v-model="interestedCourses" />
            <label for="interest-nothing">{{ $t("message.others") }}</label>
        </div>
      </div>
    </div>
    
    <button @click="submitData" class="btn btn-success" :class="subBtn">{{ $t("message.Submit") }}</button>
  </form>
</div>
</template>

<script>
import { postAxios } from "@/common/api.service";
export default {
    data() {
        return {
            userName: "",
            userAge: 0,
            userEmail: "",
            englishLevel: "",
            notes: "",
            howKnow: "",
            interestedCourses: [],
            alertClass: "",
            alertContent: "",
            phoneNumber: "",
            country: "",
            subBtn: ""

        }
        
    },

    emits: ["close"],

    methods: {

        removeAlerts() {
          
          this.alertClass = ""
          this.alertContent = ""
          this.$emit('close')
        },

        submitData(e) {
          e.preventDefault();
          const data = {name:this.userName, age: this.userAge, email:this.userEmail,
                        english_level:this.englishLevel, phone_number: this.phoneNumber,
                        country: this.country, notes: this.notes, 
                        hear_method:this.howKnow,
                        interested_in: this.interestedCourses.toString()}
          let endpoint = "api/apply/";
          postAxios(endpoint, data).then((response)=>{
            console.log(response)
            this.success = true
            this.subBtn = "disabled"
            this.alertClass = "alert-success"
            this.alertContent = "Form successfylly submitted"
          }).catch((e)=>{
            console.log(e)
            this.alertClass = "alert-danger"
            this.alertContent = "Form could not not be submitted"
          })
           setTimeout(this.removeAlerts, 4000) 
        },

        
    }
    
}
</script>

<style scoped>
form {
  margin: 2rem auto;
  max-width: 100%;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 2rem;
  background-color: #ffffff;
}

.form-cl {
  margin: 0.5rem 0;
}

label {
  font-weight: bold;
}

h2 {
  font-size: 1rem;
  margin: 0.5rem 0;
}

input,
select {
  display: block;
  width: 100%;
  font: inherit;
  margin-top: 0.5rem;
}


input[type='checkbox'],
input[type='radio'] {
  display: inline-block;
  width: 5%;
  margin-right: 1rem;
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  font-weight: normal;
}


</style>