<template>
    <div class="md:w-4/12">
        <h3>
            <span class="badge badge-success">
                Please select {{ selected_plan.num_of_sessions }} sessions from
                the calendar
            </span>
        </h3>
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">Selected Courses</th>
                    <th scope="col">Start date</th>
                    <th scope="col">Course Duration</th>
                    <th>Edit</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="slot in selected_slots" :key="slot.id">
                    <th scope="row">{{ slot.title }}</th>
                    <td>{{ dateConvert(slot.date) }}</td>
                    <td>{{ slot.duration }}</td>
                    <td>
                        <button
                            @click="$emit('remove', slot)"
                            class="btn btn-danger"
                        >
                            Remove
                        </button>
                    </td>
                </tr>
                <tr>
                    <th scope="col">Total Selected</th>
                    <th scope="col">{{ selected_slots.length }}</th>
                </tr>
            </tbody>
        </table>
        <a class="btn btn-success" @click="$emit('submitClick')" :class="buttonStatus"
            >Submit</a
        >
    </div>
</template>
<script>
export default {
    data() {
        return {

        };
    },

    props: ["selected_slots", "selected_plan"],

    emits: ["remove", "submitClick"],

    methods: {
        dateConvert(d) {
            return this.$moment(d).format('YYYY-MM-DD  HH:mm');
        },

    },

    computed: {
        buttonStatus: function () {
            if (this.selected_plan) {
                return {
                    disabled:
                        this.selected_slots.length <
                        this.selected_plan.num_of_sessions,
                    "":
                        this.selected_slots.length ==
                        this.selected_plan.num_of_sessions,
                };
            } else {
                return "";
            }
        },
    },
};
</script>
