<template>
    <dialog open>
        <header>
            <slot name="header">
                <h2>{{ title }}</h2>
            </slot>
        </header>
        <section>
            <slot></slot>
        </section>
        <menu>
            <slot name="actions">
                <button @click="$emit('close')">Close</button>
            </slot>
        </menu>
    </dialog>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
        },
    },
    emits: ["close"],
};
</script>

<style scoped>
div {
    
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: white;
    z-index: 10;
}

dialog {
    position: absolute;
    top: 20vh;
    left: 10%;
    width: 80%;
    z-index: 100;
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
}

header {
    background-color: #00235f;
    color: white;
    width: 100%;
    padding: 1rem;
}

header h2 {
    margin: 0;
}

section {
    padding: 1rem;
    background-color: white;
}

menu {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    background-color: white;
}

@media (min-width: 768px) {
    dialog {
        left: calc(50% - 20rem);
        width: 40rem;
    }
}
</style>
