import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Profile from "@/views/Profile.vue";
import Teachers from "@/views/Teachers.vue";
import BookStore from "@/views/BookStore.vue";
import AboutUs from "@/views/AboutUs.vue";
import TeacherProfile from "@/views/teachers/TeacherProfile.vue";
import SelectTeacherSessions from "@/views/Sessions/SelectTeacherSessions.vue"
import TermsAndConditions from "@/views/docs/TermsAndConditions.vue"
import PrivacyPolicy from "@/views/docs/PrivacyPolicy.vue"


const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },

  {
    path: "/terms-and-conditions",
    name: "Terms-and-Conditions",
    component: TermsAndConditions,
  },

  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },

  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
  },

  // {
  //   path: "/teachers",
  //   name: "Teachers",
  //   component: Teachers,
  // },

  {
    path: "/teachers-:courseName",
    name: "Teachers",
    component: Teachers,
  },

  {
    path: "/shop",
    name: "BookStore",
    component: BookStore,
  },

  {
    path: "/teacher-:TeacherId",
    name: "TeacherProfile",
    component: TeacherProfile,

  },
  {
    path: "/sessions/:TeacherId/:NS",
    name: "SelectTeacherSessions",
    component: SelectTeacherSessions,

  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;