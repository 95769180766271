<template>
    <section class="py-20 bgcolor overflow-hidden">
        <a href="/teachers-all">
        <h3 class="text-3xl mb-2 font-semibold leading-normal">
             {{ $t("message.OurTeachers") }}
        </h3>
        </a>
        <Carousel :itemsToShow="3.95" :autoplay="3000" :wrap-around="true">
            <Slide v-for="Teacher in Teachers.slice(0, 5)" :key="Teacher.id">
                <div class="carousel__item">
                    <teacher-slide :tid="Teacher.id" :alt="Teacher['alt']" :src="Teacher.first_page_url" 
                    :name="full_name(Teacher.user)" 
                    :calss_name=" $i18n.locale == 'fa' ? Teacher.course.course_name_f : Teacher.course.course_name"></teacher-slide>
                </div>
            </Slide>
            <template #addons>
                <Navigation />
            </template>
        </Carousel>
    </section>
</template>

<script>

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import TeacherSlide from '@/views/teachers/TeacherSlide.vue'
import { apiService } from "@/common/api.service";


export default {


data() {
    return {
    Teachers: [
    ]
    };
},


    components: {
        Carousel,
        Slide,
        Navigation,

        TeacherSlide,
    },

    methods:{
        getActiveTeachers() {
            let endpoint = "api/teachers/";
            apiService(endpoint).then((data) => {
                const midvar = [];
                midvar.push(...data);
                let midvar_shuffled = this.shuffle(midvar)
                this.Teachers = JSON.parse(JSON.stringify(midvar_shuffled));
            }).catch(e => {
                console.log(e);
            });

        },

        shuffle(array) {
            let currentIndex = array.length,  randomIndex;

            // While there remain elements to shuffle...
            while (currentIndex != 0) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
            }

            return array;
        },

        full_name(user) {
            if ( this.$i18n.locale == 'fa' ){
                return (String(user.first_name_f) + ' ' + String(user.last_name_f))
             } else {
                 return (String(user.first_name) + ' ' + String(user.last_name))
            }

        }
    },
    created() {
        this.getActiveTeachers();
    }
};
</script>

<style scoped>
.carousel__slide > .carousel__item {
  transform: scale(1);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}

.bgcolor {
    background-color: #E5F5FF
}
</style>
