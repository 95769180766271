<template>
    <div id="pclass">
        <div v-if="passed.length > 0">
            <data-table class="table table-striped nowrap"
            v-if="$store.state.auth.userIsTeacher" :title="$t('message.passedSessions')">
                <template #thead>
                    <th>{{ $t("message.row") }}</th>
                    <th>{{ $t("message.CourseName") }}</th>
                    <th>{{ $t("message.StartTime") }}</th>
                    <th>{{ $t("message.Booked") }}</th>
                    <th>{{ $t("message.Notes") }}</th>
                    
                </template>
                <template #tbody>
                    <tr v-for="(p, index) in passed" :key="p.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ p.teacher.course.course_name }}</td>
                        <td>{{ formatDate(p.start_time) }}</td>
                        <td><i :class="iconTrueFalse(p.is_sold)"></i></td>
                        <td v-if="p.is_sold"><button @click="openNote(p.id, p.session_notes)" class="btn btn-sm btn-primary">
                            {{ $t("message.LeaveNote") }}
                            </button></td>
                        <td v-else></td>
                        
                    </tr>
                </template>
            </data-table>
        <!-- /.teachers -->
        <!-- students -->
            <data-table class="table table-striped nowrap"
            v-else :title="$t('message.passedSessions')">
                <template #thead>
                    <th>{{ $t("message.row") }}</th>
                    <th>{{ $t("message.CourseName") }}</th>
                    <th>{{ $t("message.StartTime") }}</th>
                    <th>{{ $t("message.TeacherNotes") }}</th>
                </template>
                <template #tbody>
                    <tr v-for="(p, index) in passed" :key="p.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ p.teacher_slot.teacher.course.course_name }}</td>
                        <td>{{ formatDate(p.teacher_slot.start_time) }}</td>
                        <td>{{ p.session_teacher_notes }}</td>
                    </tr>
                </template>
            </data-table>
        <!-- /students -->
        </div>

        <div class="space-12"></div>
        <base-dialog v-if="noteOpen" :title="$t('message.SessionNotes')">
            <template #default>
                <textarea id="teahcer-note" name="teahcer-note" rows="4" cols="50" v-model="Notes">
                </textarea>
            </template>
            <template #actions>
                <div class="btn-group">
                    <button @click="submitNotes"
                    class="btn btn-sm btn-info">{{ $t('message.submit') }}</button>
                    <button @click="closeNotes" class="btn btn-danger btn-success">{{ $t('message.close') }}</button>
                </div>
            </template>
        </base-dialog>
    </div>
</template>

<script>
import DataTable from "@/components/Tables/DataTable.vue"
import { iconTrueFalse, formatDate } from "@/common/functions"
import BaseDialog from '@/components/Modal/BaseDialog.vue'
import { postAxios } from "@/common/api.service";

export default {

    data() {
        return {
            noteOpen: false,
            noteId: null,
            Notes: "",

        }
    },
    props: ['passed'],

    methods: {
        iconTrueFalse,
        formatDate,

        openNote(id, notes) {
            if (!this.noteOpen) {
                this.noteOpen = true;
                this.noteId = id;
                this.Notes = notes
            }
        },

        closeNotes() {
            this.noteOpen = false;
            this.noteId = null;
            this.Notes = ""
        },

        submitNotes() {
            if (this.Notes != "") {
                let endpoint = "api/submit-session-notes/"
                let data = {slot_id:this.noteId, session_teacher_notes:this.Notes}
                postAxios(endpoint, data).then((response)=>{
                    console.log(response)
                    this.noteId = null;
                    this.noteOpen = false;
                }).catch((e)=>{
                    console.log(e)
                })

            }

        }
    },

    components: {
        DataTable,
        BaseDialog
        
    }
}
</script>