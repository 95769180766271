<template>
    <vue-pdf src="./static/privacypolicy.pdf" v-for="page in numOfPages" :key="page" :page="page" />
</template>

<script>
import { VuePdf } from 'vue3-pdfjs/esm';
export default {
    data() {
        return{
            numOfPages: 4,
        }
        
    },

    components: {
        VuePdf
    },
}

</script>