import { createApp} from 'vue'
import { createStore } from 'vuex';
import { createI18n } from 'vue-i18n'
import moment from 'moment-timezone';
import router from "./router";
import App from './App.vue';
import { languages } from './common/Langs/index.js'
import { defaultLocale } from './common/Langs/index.js'
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap-4"
import 'jquery/dist/jquery.min.js'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import authModule from "./common/auth/index.js"
import SiteData from "./common/SiteData/index.js"
import VueLazyLoad from 'vue3-lazyload'

const store = createStore({
    
    modules: {
        auth:authModule,
        SiteData: SiteData
    }
});

const messages = Object.assign(languages)


  const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: 'en',
    messages
  });
  moment.tz.setDefault('local')
  let app = createApp(App)
  app.config.globalProperties.$moment=moment;


app.use(store).use(router).use(i18n).use(VueLazyLoad, {
  loading: 'Loading image',
  error: 'No image',
  lifecycle:{
    loading: (el) => {
      console.log('loading', el)
    },
    error: (el) => {
      console.log('error', el)
    },
    loaded: (el) => {
      console.log('loaded', el)
    }
  }
}).mount('#app')
