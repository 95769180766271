<template>
    <div class="courses">
        <index-navbar />
        <div class="container mt-24">
            <div class="mt-2">
                <h6 class="font-semibold text-xl text-blueGray-600">
                    {{ $t("message.SelectCourse") }}
                </h6>
            </div>
            <div
                v-for="course in courses"
                :key="course.id"
                class="d-inline-flex d-flex justify-content-cente px-2"
            >
                <button
                    @click="filterTeachers(course.course_name)"
                    class="btn btn-sm btn-primary"
                >
                    {{
                        $i18n.locale == "fa"
                            ? course.course_name_f
                            : course.course_name
                    }}
                </button>
            </div>
            <div
                class="d-inline-flex d-flex justify-content-cente px-2"
            >
                <button
                    @click="selected_course=null"
                    class="btn btn-sm btn-success"
                >
                    {{
                        $t("message.all")
                    }}
                </button>
            </div>
        </div>
        <div class="flex flex-wrap px-3">
            <div></div>
            <div
                v-for="teacher in selectedTeachers"
                :key="teacher.id"
                class="pt-6 w-full md:w-4/12 px-4 text-center"
            >
                <div
                    class="
                        relative
                        flex flex-col
                        min-w-0
                        break-words
                        bg-nimcat
                        w-full
                        mb-8
                        shadow-lg
                        rounded-lg
                    "
                >
                    <div class="px-4 py-5 flex-auto">
                        <div
                            class="
                                text-white
                                p-3
                                text-center
                                inline-flex
                                items-center
                                justify-center
                                w-12
                                h-12
                                mb-5
                                shadow-lg
                                rounded-full
                                bg-red-400
                            "
                        >
                            <img
                                alt=".."
                                v-lazy="teacher.first_page_url"
                                class="
                                    shadow-lg
                                    rounded-full
                                    mx-auto
                                    max-w-120-px
                                "
                            />
                        </div>
                        <h6
                            class="text-xl font-semibold"
                            v-html="full_name(teacher.user)"
                        ></h6>
                        <p
                            v-if="teacher.course != null"
                            class="mt-2 mb-4 text-blueGray-500"
                        >
                            {{
                                $i18n.locale == "fa"
                                    ? teacher.course.course_name_f
                                    : teacher.course.course_name
                            }}
                        </p>
                    </div>
                    <a
                        :href="teacherPage(teacher.id)"
                        class="
                            bg-emerald-500
                            text-white
                            active:bg-emerald-600
                            text-xs
                            bg-nimcat-blue
                            text-nimcat
                            font-bold
                            uppercase
                            px-4
                            py-2
                            rounded
                            shadow
                            hover:shadow-lg
                            outline-none
                            focus:outline-none
                            ease-linear
                            transition-all
                            duration-150
                        "
                        type="button"
                    >
                        <i class="fas fa-user-alt"></i>
                        {{ $t("message.Details") }}
                    </a>
                </div>
            </div>
        </div>

        <footer-component />
    </div>
</template>


<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/FooterAdmin.vue";
import { apiService } from "@/common/api.service";

export default {
    name: "Teachers",

    data() {
        return {
            Teachers: [],
            courses: [],
            selected_course: null,
        };
    },

    methods: {
        getActiveTeachers() {
            let endpoint = "api/teachers/";
            apiService(endpoint)
                .then((data) => {
                    console.log(data)
                    const midvar = [];
                    midvar.push(...data);
                    this.Teachers = JSON.parse(JSON.stringify(midvar));
                    console.log(this.Teachers);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        getCourses() {
            let endpoint = "api/courses/";
            apiService(endpoint)
                .then((data) => {
                    const midvar = [];
                    midvar.push(...data);
                    this.courses = JSON.parse(JSON.stringify(midvar));
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        filterTeachers(course) {
            this.selected_course = course;
        },

        full_name(user) {
            if (this.$i18n.locale == "fa") {
                return (
                    String(user.first_name_f) + " " + String(user.last_name_f)
                );
            } else {
                return String(user.first_name) + " " + String(user.last_name);
            }
        },
        teacherPage(id) {
            return "teacher-" + String(id);
        },

        filterCourses() {
            const courseName = this.$route.params.courseName;
            if (courseName != "all") {
                this.selected_course = courseName
            }
        }
    },

    computed: {
        selectedTeachers() {
            let midvar = [];
            if (this.selected_course == null) {
                return this.Teachers;
            } else {
                this.Teachers.forEach((teacher) => {
                    if (teacher.course.course_name == this.selected_course) {
                        midvar.push(teacher);
                    }
                });
                return midvar;
            }
        },
    },

    components: {
        IndexNavbar,
        FooterComponent,
    },

    created() {
        this.getCourses();
        this.getActiveTeachers();
        this.filterCourses();
    },
    
};
</script>