<template>
  <footer class="relative text-nimcat bg-nimcat-blue pt-8 pb-6">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0);"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-blueGray-200 text-nimcat-blue fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4">
      <div class="flex flex-wrap text-center lg:text-left">
        <div class="w-full lg:w-6/12 px-4">
          <h4 class="text-3xl font-semibold text-nimcat">{{ $t("message.footerVision") }}</h4>
          <h5 class="text-lg mt-0 mb-2 text-nimcat">
            {{ $t("message.footerLinks") }}
          </h5>
          <div class="mt-6 lg:mb-0 mb-6">
            <a
              class="button bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              href='https://www.facebook.com/Nimcat-Online-School-113536141098790'
              target="_blank"
            >
              <i class="fab fa-facebook"></i>
            </a>
            <a
              class="button bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              href='https://instagram.com/nimcatonlineschool/'
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              class="button bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
              href='https://wa.me/+61481841920'
              target="_blank"
            >
              <i class="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>
        <div class="w-full lg:w-6/12 px-4">
          <div class="flex flex-wrap items-top mb-6">
            <div class="w-full lg:w-4/12 px-4 ml-auto">
              <ul class="list-unstyled">
                <li>
                  <a
                    class="text-nimcat hover:text-nimcat font-semibold block pb-2 text-sm"
                    href="/about-us"
                  >
                    {{ $t("message.About") }}
                  </a>
                </li>
                <li>
                  <a
                    class="text-nimcat hover:text-nimcat font-semibold block pb-2 text-sm"
                    href="/shop"
                  >
                    {{ $t("message.Shop") }}
                  </a>
                </li>
                <li>
                  <a
                    class="text-nimcat hover:text-nimcat font-semibold block pb-2 text-sm"
                    href="/teachers-all"
                  >
                    {{ $t("message.Teachers") }}
                  </a>
                </li>

                <li>
                  <a
                    class="text-nimcat hover:text-nimcat font-semibold block pb-2 text-sm"
                    @click="$emit('openJoinUs')"
                    href="#"
                  >
                    {{ $t("message.JoinUs") }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="w-full lg:w-4/12 px-4">
              
              <ul class="list-unstyled">
               <li>
                  <a
                    class="text-nimcat hover:text-nimcat font-semibold block pb-2 text-sm"
                    href="/terms-and-conditions"
                  >
                    {{ $t("message.Terms&Cond") }}
                    
                  </a>
                </li>

                <li>
                  <a
                    class="text-nimcat hover:text-nimcat font-semibold block pb-2 text-sm"
                    href="/privacy-policy"
                  >
                    {{ $t("message.Privacy") }}
                    
                  </a>
                </li>
               
                <li>
                  <a
                    class="text-nimcat font-semibold block pb-2 text-sm"
                    href="#"
                    @click="$emit('openContacts')"
                  >
                    {{ $t("message.ContactUs") }}
                  </a>
                </li>

                <li>
                  <a
                    class="text-nimcat font-semibold block pb-2 text-sm"
                    href="/helpdesk/kb/"
                  >
                    {{ $t("message.FAQ") }}
                  </a>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-6 border-blueGray-300" />
      <div
        class="flex flex-wrap items-center md:justify-between justify-center"
      >
        <div class="w-full md:w-4/12 px-4 mx-auto text-center">
          <div class="text-sm text-nimcat font-semibold py-1">
            Copyright © {{ date }} 
            <a
              href="#"
              class="text-blueGray-500 hover:text-blueGray-800"
            >
              Nimcat Online School
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },

  emits: ['openContacts', 'openJoinUs']
};
</script>
<style scoped>
a.button {
  -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    padding-right: 13px;
    padding-left: 13px;
    padding-top: 10px;
    padding-bottom: 7px;

}

</style>
