<template>

        <page-section title="">
            <template #main>
                <div class="w-full md:w-4/12 px-4 mr-auto ml-auto">
                    <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-emerald-500"
                    >
                    <img
                        alt="..."
                        src="/static/img/littlegirl.jpeg"
                        class="w-full align-middle rounded-t-lg"
                    />
                    <blockquote class="relative p-8 mb-4">
                        <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 583 95"
                        class="absolute left-0 w-full block h-95-px -top-94-px"
                        >
                        <polygon
                            points="-30,95 583,95 583,65"
                            class="text-emerald-500 fill-current"
                        ></polygon>
                        </svg>
                        <h4 class="text-xl font-bold text-white">
                        مزایای نیمکت
                        </h4>
                        <p class="text-md font-light mt-2 text-white farsi">
                        ما در کنار موفقیت و برتری فرزندان فارسی زبان در عرصه های مختلف فرهنگ و هنر و زبان فارسی،
                        به آموزش مهربانی و شادی با برگزاری کلاسهای منحصر به فرد، جذاب و خلق لحظات به یاد ماندنی 
                        نیز باور داریم</p>
                        <p class="text-md font-light mt-2 text-white farsi">
                        هدف ما ساختن مسیری زیبا در آموزش کودکان دو زبانه برای داشتن آینده ای روشن است. 
                        </p>
                    </blockquote>
                    </div>
                </div>

                <div class="w-full md:w-6/12 px-4">
                    <div class="flex flex-wrap">
                    <div class="w-full md:w-6/12 px-4">
                        <div class="relative flex flex-col mt-4">
                        <div class="px-4 flex-auto">
                            <div
                            class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                            >
                            <i class="fas fa-sitemap"></i>
                            </div>
                            <h6 class="text-xl mb-1 font-semibold">
                           یادگیری مفید
                            </h6>
                            <p class="mb-4 text-blueGray-500">
                            برنامه های آموزشی تاثیر محور، همراه با شعر، قصه خوانی، بازی های متناسب با هر گروه سنی و کتاب
                            کار اختصاصی با تالیف مدرسه آنلاین نیمکت
                            </p>
                        </div>
                        </div>
                        <div class="relative flex flex-col min-w-0">
                        <div class="px-4 flex-auto">
                            <div
                            class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                            >
                            <i class="fas fa-drafting-compass"></i>
                            </div>
                            <h6 class="text-xl mb-1 font-semibold">
                            آموزگاران با تجربه و متخصص
                            </h6>
                            <p class="mb-4 text-blueGray-500">
                            حساسیت بالا در مصاحبه و گزینش آموزگاران از بین مدرسان برتر و با سالها تجربه در زمینه تدریس به کودکان 
                            </p>
                        </div>
                        </div>
                    </div>
                    <div class="w-full md:w-6/12 px-4">
                        <div class="relative flex flex-col min-w-0 mt-4">
                        <div class="px-4 flex-auto">
                            <div
                            class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                            >
                            <i class="fas fa-file-alt"></i>
                            </div>
                            <h6 class="text-xl mb-1 font-semibold">
                            دسترسی آسان
                            </h6>
                            <p class="mb-4 text-blueGray-500">
                                برگزاری کلاس ها از طریق پلتفرم گوگل میت، قابل دسترس همگان، منعطف با زمان و روز،
                            متناسب با شرایط زندگی
                            </p>
                        </div>
                        
                        </div>
                        <div class="relative flex flex-col min-w-0">
                        <div class="px-4 flex-auto">
                            <div
                            class="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white"
                            >
                            <i class="fas fa-newspaper"></i>
                            </div>
                            <h6 class="text-xl mb-1 font-semibold">کشف استعداد و توانمندی</h6>
                            <p class="mb-4 text-blueGray-500">
                            پرورش توانمندی ها و استعدادهای کودکان و نوجوانان همراه با تشویق آنان در راستای پرورش 
                            ذهن خلاق با استفاده از ابزار نوین، روش تدریس خلاقانه و محتوای مناسب
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
          
            </template>
        </page-section>


</template>

<script>
import PageSection from '@/components/Section/pageSection.vue'
import littleGirl from '@/assets/img/littlegirl.jpeg'
export default {
    data() {
        return {
            lg: littleGirl
        }
        
    },

    components:{
        PageSection
        
    }
}
</script>

<style scoped>

.farsi {
    direction: rtl;
}
</style>