<template>
    <div>
      <slot name="header">
        <h2>{{ title }}</h2>
      </slot>
      <table class="table" id="datatable">
        <thead>
        <tr>
            <slot name="thead">
                <!-- <th>..</th> -->
            </slot>
        </tr>
        </thead>
        <tbody>
            <slot name="tbody">
                <!-- <tr>
                        <td>...
                    </tr> -->
            </slot>
          
        </tbody>
      </table>    
  </div>
</template>

<script>
import $ from 'jquery';

export default {


    props: {
        title: {
            type: String,
            required: false,
        },
    },

    mounted() {
        $('#datatable').DataTable();
    }
 
}
</script>