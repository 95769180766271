import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default {
    state() {
        return {
            userIsAuth: false,
            userId: null,
            userFirstName: null,
            userLastName: null,
            userFirstNameF: null,
            userLastNameF: null,
            userIsTeacher: null,
            userFreeSessionUsed: null,
        };
    },
    actions,
    mutations,
    getters
}