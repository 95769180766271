export default {
    isLoggedIn(state) {
        return state.user !== null;
    },

    isTeacher(state) {
        if (state.auth != null) {
            return state.auth.userIsTeacher
        } else {
            return false
        }
    }
}