<template>
    <div class="container mx-auto px-4">
        <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                    alt="..."
                    class="max-w-full rounded-lg shadow-lg"
                    :src="Teacher.profile_url"
                />
            </div>
            <div class="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div class="md:pr-12">
                    
                        <img class="
                            p-3
                            text-center
                            inline-flex
                            items-center
                            justify-center
                            w-25
                            h-25
                            mb-6 rounded-full" src="/static/img/icc.jpg"/>
                    <h3
                        class="text-3xl font-semibold"
                        v-html="full_name()"
                    ></h3>
                    <p class="mt-4 items-center text-lg leading-relaxed text-blueGray-500"
                        v-html="$i18n.locale=='fa' ? Teacher.description_f : Teacher.description"
                    ></p>
                    <ul class="list-none mt-6">
                        <li class="py-2" v-if="!$store.state.auth.userFreeSessionUsed">
                            <div class="flex items-center justify-content-center">
                                <button class="btn btn-outline-primary" @click="selectPlan('free_demo')">
                                    <i class="fas fa-glasses"></i>  {{ $t("message.DemoBut") }}</button>
                            </div>
                        </li>
                        
                        <li class="py-2">
                            <div class="flex items-center justify-content-center">
                                <button class="btn btn-success" @click="openDialog()">
                                    <i class="fas fa-bookmark"></i> {{ $t("message.ReserveBut") }}</button>
                            </div>
                        </li>
                        <!-- GIFT REMOVED -->
                        <!-- <li class="py-2">
                            <div class="flex items-center justify-content-center">
                                <button class="btn btn-warning text-white">
                                    <i class="fas fa-gift"></i> {{ $t("message.GiftBut") }}</button>
                            </div>
                        </li> -->
                        </ul>

                        <base-dialog v-if="reserveDialog" :title='$t("message.ReserveBut")'>
                            <template #default>
                                <ul class="list-none mt-6" style="direction:rtl;">
                                    <li v-for="plan in plans" :key="plan.id" class="py-2">
                                        <div class="flex items-center">
                                        <div>
                                            <span
                                            class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-emerald-600 bg-nimcat mr-3"
                                            >
                                            <i class="fas fa-glasses"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <button @click="selectPlan(plan)">
                                                <h6 class="text-blueGray-500">
                                                 {{ $t("message.NumOfSessions") }} {{ plan.num_of_sessions }} {{ $t("message.Session") }} - {{ $t("message.price") }} : {{ getPrice(plan.num_of_sessions, plan.discount) }} USD
                                                </h6>
                                                <p>{{ $t("message.DiscountAmount") }} : {{ plan.discount }} USD</p>
                                            </button>
                                        </div>
                                        </div>
                                    </li>
                                    </ul>
                            </template>
                            <template #actions>
                                <button @click="close" class="btn btn-danger btn-success">{{ $t('message.close') }}</button>
                            </template>

                        </base-dialog>



                    <!-- <plans
                        :course="Teacher.course"
                        @select_plan="selectPlan"
                    ></plans> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Plans from "./Plans.vue";
import { apiService } from "@/common/api.service";
import BaseDialog from "@/components/Modal/BaseDialog.vue"
import icc from "@/assets/img/icc.jpg"

export default {
    data() {
        return {
            Teacher: [],
            plans:[],
            reserveDialog: false,
            icc: icc,
        };
    },

    emits: ['selectPlan'],

    // components: {
    //     Plans,
    // },

    // provide: {
    //     selected_plan: null,
    // },

    methods: {
        getTeacherData() {
            let endpoint = "api/teachers/" + this.$route.params.TeacherId + "/";
            apiService(endpoint)
                .then((data) => {
                    this.Teacher = JSON.parse(JSON.stringify(data));
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        openDialog() {
            this.reserveDialog = true
        },

        getPaymentPlans() {
            let endpoint = 'api/plans';
            apiService(endpoint).then((data) => {
                    const midvar = [];
                    midvar.push(...data);
                    this.plans = JSON.parse(JSON.stringify(midvar));
                }).catch(e => {
                    console.log(e);
                });
            },

        full_name() {
            if (this.Teacher.user) {
                if(this.$i18n.locale == "fa"){
                    return (
                    String(this.Teacher.user.first_name_f) +
                    " " +
                    String(this.Teacher.user.last_name_f)
                )
                }
                return (
                    String(this.Teacher.user.first_name) +
                    " " +
                    String(this.Teacher.user.last_name)
                );
            } else {
                return "";
            }
        },

        selectPlan(plan) {
            this.$emit('selectPlan', plan);
        },

        getPrice(ns, discount) {
            return this.Teacher.course.base_price*ns - discount
        },

        close() {
            this.reserveDialog = false
        }
    },

    components: {
        BaseDialog
    },

    created() {
        this.getTeacherData();
        this.getPaymentPlans();
    },
};
</script>
