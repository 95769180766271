export default {
    setAuthUser(state, payload) {
        state.userIsAuth = payload.userIsAuth,
        state.userId= payload.id, 
        state.userFirstName= payload.first_name,
        state.userLastName= payload.last_name,
        state.userFirstNameF= payload.first_name_f,
        state.userLastNameF= payload.last_name_f,
        state.userIsTeacher= payload.is_teacher,
        state.userFreeSessionUsed= payload.free_session_used
    },


}