<template>
    <div class="terms">
        <card :title="Title">
            <template #default>
                <b>About the Website</b>
                <p>
                (a) Welcome to nimcatonlineschool.com (the 'Website'). The Website
                Online Education and Tutoring Courses (the 'Services ').
                </p><p>
                (b) The Website is operated by Nimcat Online School(ABN 92 382 261 006). Access
                to and use of the Website, or any of its associated Products or Services, is
                provided by Nimcat Online School. Please read these terms and conditions (the
                'Terms ') carefully. By using, browsing and/or reading the Website, this signifies
                that you have read, understood and agree to be bound by the Terms. If you do
                not agree with the Terms, you must cease usage of the Website, or any of
                Services, immediately.
                </p><p>
                (c) Nimcat Online School reserves the right to review and change any of the Terms
                by updating this page at its sole discretion. When Nimcat Online School updates
                the Terms, it will use reasonable endeavours to provide you with notice of
                updates to the Terms. Any changes to the Terms take immediate effect from the
                date of their publication. Before you continue, we recommend you keep a copy of
                the Terms for your records.
                </p>
                <b>Acceptance of the Terms</b>
                <p>
                    (a) You accept the Terms by remaining on the Website. You may also accept
                    the Terms by clicking to accept or agree to the Terms where this option is
                    made available to you by Nimcat Online School in the user interface.
                </p>
                <b>Registration to use the Services</b>
                <p>
                    (a) In order to access the Services, you must first register for an account through the
                    Website (the <strong>'Account'</strong>).
                </p><p>
                    (b) As part of the registration process, or as part of your continued use of the
                    Services, you may be required to provide personal information about
                    yourself (such as identification or contact details), including:
                    <ol>
                        <li> Email address</li>
                        <li> Preferred username</li>
                        <li> Mailing address</li>
                        <li> Telephone number</li>
                        <li> Password</li>
                        <li> Student's date of birth(c) You warrant that any information you give to Nimcat Online School in the course of
                        completing the registration process will always be accurate, correct and up to date.</li>
                    </ol>
                    </p><p>
                    (d) Once you have completed the registration process, you will be a registered
                    member of the Website ('Member ') and agree to be bound by the Terms.
                    </p><p>
                    (e) You may not use the Services and may not accept the Terms if:
                    <ol>
                    <li>(i) you are not of legal age to form a binding contract with Nimcat
                    Online School; or</li>
                    <li>(ii) you are a person barred from receiving the Services under the laws of
                    Australia or other countries including the country in which you are
                    resident or from which you use the Services.</li>
                    </ol>
                    </p>
                    <b>Your obligations as a Member</b>
                    <p>
                        (a) As a Member, you agree to comply with the following:
                        <ol>
                            <li>(i) you will use the Services only for purposes that are permitted by:</li>
                            <ol>
                                <li>1. the Terms; and</li>
                                <li>2. any applicable law, regulation or generally accepted practices
                                    or guidelines in the relevant jurisdictions;</li>
                            </ol>
                            <li>(ii) you have the sole responsibility for protecting the confidentiality of
                                your password and/or email address. Use of your password by any
                                other person may result in the immediate cancellation of the Services;</li>
                            <li>(iii) any use of your registration information by any other person, or third
                            parties, is strictly prohibited. You agree to immediately notify Nimcat
                            Online School of any unauthorised use of your password or email
                            address or any breach of security of which you have become aware;</li>

                            <li>(iv) access and use of the Website is limited, non-transferable and allows
                            for the sole use of the Website by you for the purposes of Nimcat
                            Online School providing the Services;</li>
                            <li>(v) you will not use the Services or the Website in connection with any
                            commercial endeavours except those that are specifically endorsed or
                            approved by the management of Nimcat Online School;</li>
                            <li>(vi) you will not use the Services or Website for any illegal and/or
                            unauthorised use which includes collecting email addresses of Members
                            by electronic or other means for the purpose of sending unsolicited email
                            or unauthorised framing of or linking to the Website;</li>
                            <li>(vii) you agree that commercial advertisements, affiliate links, and other forms of
                            solicitation may be removed from the Website without notice and may result in
                            termination of the Services. Appropriate legal action will be taken by Nimcat Online School for any illegal or unauthorised use of the Website; and</li> 
                            <li>(viii) you acknowledge and agree that any automated use of the Website or
                            its Services is prohibited.</li>
                        </ol>
                    </p>
                    <b>Payment</b>
                    <p>(a) Where the option is given to you, you may make payment for the Services (the
                        ' Services Fee') by way of:
                        <ol>
                            <li>(i) Credit Card Payment (<strong>'Credit Card'</strong>)</li>
                            <li>(ii) PayPal (<strong>'PayPal'</strong>)</li>
                        </ol>
                    </p>
                    <p>(b) All payments made in the course of your use of the Services are made using
                    Paypal. In using the Website, the Services or when making any payment in
                    relation to your use of the Services, you warrant that you have read, understood
                    and agree to be bound by the Paypal terms and conditions which are available on
                    their website.</p>
                    <p>(c) You acknowledge and agree that where a request for the payment of the
                    Services Fee is returned or denied, for whatever reason, by your financial
                    institution or is unpaid by you for any other reason, then you are liable for any
                    costs, including banking fees and charges, associated with the Services Fee.</p>
                    <p>(d) You agree and acknowledge that Nimcat Online School can vary the Services
                    Fee at any time.</p>
                    <b>Refund Policy</b>
                    <p>
                    Nimcat Online School will only provide you with a refund of the Services Fee in the event
                    they are unable to continue to provide the Services or if the manager of Nimcat Online
                    School makes a decision, at its absolute discretion, that it is reasonable to do so under
                    the circumstances (the 'Refund').</p>
                    <b>Copyright and Intellectual Property</b>
                    <p>(a) The Website, the Services and all of the related products of Nimcat Online
                        School are subject to copyright. The material on the Website is protected by
                        copyright under the laws of Australia and through international treaties. Unless
                        otherwise indicated, all rights (including copyright) in the Services and
                        compilation of the Website (including but not limited to text, graphics, logos,
                        button icons, video images, audio clips, Website, code, scripts, design elements
                        and interactive features) or the Services are owned or controlled for these
                        purposes, and are reserved by Nimcat Online School or its contributors.</p>
                    <p>(b) All trademarks, service marks and trade names are owned, registered and/or 
                        licensed by Nimcat Online School, who grants to you a worldwide, nonexclusive, 
                        royalty-free, revocable license whilst you are a Member to:
                        <ol>
                        <li>(i) use the Website pursuant to the Terms;</li>
                        <li>(ii) copy and store the Website and the material contained in the Website
                        in your device's cache memory; and</li>
                        <li>(iii) print pages from the Website for your own personal and noncommercial use.
                        Nimcat Online School does not grant you any other rights whatsoever in relation
                        to the Website or the Services. All other rights are expressly reserved by Nimcat
                        Online School.</li>
                        </ol>
                    </p>
                    <p>(c) Nimcat Online School retains all rights, title and interest in and to the Website
                        and all related Services. Nothing you do on or in relation to the Website will
                        transfer any:
                        <ol>
                        <li>(i) business name, trading name, domain name, trade mark, industrial
                        design, patent, registered design or copyright, or</li>
                        <li>(ii) a right to use or exploit a business name, trading name, domain name,
                        trade mark or industrial design, or</li>
                        <li>(iii) a thing, system or process that is the subject of a patent, registered
                        design or copyright (or an adaptation or modification of such a thing,
                        system or process),
                        to you.</li>
                        </ol>
                    </p>
                     <p>(d) You may not, without the prior written permission of Nimcat Online School and
                        the permission of any other relevant rights owners: broadcast, republish, up-load
                        to a third party, transmit, post, distribute, show or play in public, adapt or change
                        in any way the Services or third party Services for any purpose, unless otherwise
                        provided by these Terms. This prohibition does not extend to materials on the
                        Website, which are freely available for re-use or are in the public domain.</p>
                    <b>Privacy</b>
                    <p>(a) Nimcat Online School takes your privacy seriously and any information
                    provided through your use of the Website and/or Services are subject to Nimcat
                    Online School's Privacy Policy, which is available on the Website.</p>
                    <b>General Disclaimer</b>
                    <p>(a) Nothing in the Terms limits or excludes any guarantees, warranties,
                    representations or conditions implied or imposed by law, including the Australian 
                    Consumer Law (or any liability under them) which by law may not be limited
                    or excluded.</p>
                    
                    <p>(b) Subject to this clause, and to the extent permitted by law:
                        <ol>
                            <li>(i) all terms, guarantees, warranties, representations or conditions which
                                are not expressly stated in the Terms are excluded; and</li>
                            <li>(ii) Nimcat Online School will not be liable for any special, indirect or
                                consequential loss or damage (unless such loss or damage is reasonably
                                foreseeable resulting from our failure to meet an applicable Consumer
                                Guarantee), loss of profit or opportunity, or damage to goodwill arising out
                                of or in connection with the Services or these Terms (including as a result
                                of not being able to use the Services or the late supply of the Services),
                                whether at common law, under contract, tort (including negligence), in
                                equity, pursuant to statute or otherwise.</li>
                        </ol></p>
                         <p>(c) Use of the Website and the Services is at your own risk. Everything on the
                            Website and the Services is provided to you "as is" and "as available" without
                            warranty or condition of any kind. None of the affiliates, directors, officers,
                            employees, agents, contributors and licensors of Nimcat Online School make
                            any express or implied representation or warranty about the Services or any
                            products or Services (including the products or Services of Nimcat Online
                            School) referred to on the Website, includes (but is not restricted to) loss or
                            damage you might suffer as a result of any of the following:
                            <ol>
                            <li>(i) failure of performance, error, omission, interruption, deletion, defect,
                            failure to correct defects, delay in operation or transmission, computer
                            virus or other harmful component, loss of data, communication line
                            failure, unlawful third party conduct, or theft, destruction, alteration or
                            unauthorised access to records;</li>
                            <li>(ii) the accuracy, suitability or currency of any information on the Website,
                            the Services, or any of its Services related products (including third party
                            material and advertisements on the Website);</li>
                            <li>(iii) costs incurred as a result of you using the Website, the Services or any of
                            the products of Nimcat Online School; and</li>
                            <li>(iv) the Services or operation in respect to links which are provided for
                            your convenience.</li>
                            </ol>
                        </p>
                        <b>Limitation of liability</b>
                        <p>(a) Nimcat Online School's total liability arising out of or in connection with the
                        Services or these Terms, however arising, including under contract, tort (including
                        negligence), in equity, under statute or otherwise, will not exceed the resupply of
                        the Services to you.</p>
                        <p>(b) You expressly understand and agree that Nimcat Online School, its affiliates,
                        employees, agents, contributors and licensors shall not be liable to you for any
                        direct, indirect, incidental, special consequential or exemplary damages which
                        may be incurred by you, however caused and under any theory of liability. This
                        shall include, but is not limited to, any loss of profit (whether incurred directly or
                        indirectly), any loss of goodwill or business reputation and any other intangible
                        loss.</p>
                        <b>Termination of Contract</b>
                        <p>(a) The Terms will continue to apply until terminated by either you or by Nimcat
                        Online School as set out below.</p>
                        <p>(b) If you want to terminate the Terms, you may do so by:</p>
                        <ol>
                        <li>(i) providing Nimcat Online School with 1 days' notice of your intention
                        to terminate; and</li>
                        <li>(ii) closing your accounts for all of the services which you use, where Nimcat
                        Online School has made this option available to you.</li>
                        </ol>
                        <p>
                        Your notice should be sent, in writing, to Nimcat Online School via the 'Contact
                        Us' link on our homepage.</p>
                        <p>(c) Nimcat Online School may at any time, terminate the Terms with you if:</p>
                        <ol>
                        <li>(i) you have breached any provision of the Terms or intend to breach
                        any provision;</li>
                        <li>(ii) Nimcat Online School is required to do so by law;</li>
                        <li>(iii) the provision of the Services to you by Nimcat Online School is, in
                        the opinion of Nimcat Online School, no longer commercially viable.</li>
                        </ol>
                        <p>
                        (d) Subject to local applicable laws, Nimcat Online School reserves the right to
                        discontinue or cancel your membership at any time and may suspend or deny, in
                        its sole discretion, your access to all or any portion of the Website or the
                        Services without notice if you breach any provision of the Terms or any
                        applicable law or if your conduct impacts Nimcat Online School's name or
                        reputation or violates the rights of those of another party.</p>
                        <b>Indemnity</b>
                        <p>(a) You agree to indemnify Nimcat Online School, its affiliates, employees, agents,
                        contributors, third party content providers and licensors from and against:
                        <ol>
                        <li>(i) all actions, suits, claims, demands, liabilities, costs, expenses, loss and
                            damage (including legal fees on a full indemnity basis) incurred, suffered
                            or arising out of or in connection with Your Content;
                        </li>
                        <li>(ii) any direct or indirect consequences of you accessing, using or transacting
                            on the Website or attempts to do so; and/or</li>
                        <li>(iii) any breach of the Terms.</li>
                        </ol>
                        </p>
                        <b>Dispute Resolution</b>
                        <div class="subtitle">
                            <p><strong>(a) Compulsory:</strong></p>
                            <p>If a dispute arises out of or relates to the Terms, either party may not
                                commence any Tribunal or Court proceedings in relation to the dispute, unless
                                the following clauses have been complied with (except where urgent
                                interlocutory relief is sought).</p>
                            <p><strong>(b) Notice:</strong></p>
                            <p>A party to the Terms claiming a dispute ('Dispute') has arisen under the
                            Terms, must give written notice to the other party detailing the nature of the
                            dispute, the desired outcome and the action required to settle the Dispute.</p>
                            <p><strong>(c) Resolution:</strong></p>
                            <p>On receipt of that notice ('Notice') by that other party, the parties to the Terms
                            ('Parties ') must:
                            (i) Within 7 days of the Notice endeavour in good faith to resolve the
                            Dispute expeditiously by negotiation or such other means upon which
                            they may mutually agree;
                            (ii) If for any reason whatsoever, 30 days after the date of the Notice, the Dispute
                            has not been resolved, the Parties must either agree upon selection of a
                            mediator or request that an appropriate mediator be appointed by the
                            President of the Commonwealth Ombudsman or his or her nominee;
                            (iii) The Parties are equally liable for the fees and reasonable expenses of a
                            mediator and the cost of the venue of the mediation and without limiting
                            the foregoing undertake to pay any amounts requested by the mediator
                            as a pre-condition to the mediation commencing. The Parties must each
                            pay their own costs associated with the mediation;
                            (iv) The mediation will be held in Commonwealth Ombudsman,</p>
                            <p><strong>(d) Confidential:</strong></p>
                            <p>All communications concerning negotiations made by the Parties arising out of and in
                            connection with this dispute resolution clause are confidential and to the extent
                            possible, must be treated as "without prejudice" negotiations for the purpose
                            of applicable laws of evidence.</p>
                            <p><strong>(e) Termination of Mediation:</strong></p>
                            <p>If 90 days have elapsed after the start of a mediation of the Dispute and the
                            Dispute has not been resolved, either Party may ask the mediator to terminate
                            the mediation and the mediator must do so.</p>
                        </div>
                        <b>Venue and Jurisdiction</b>
                        <p>(a) The Services offered by Nimcat Online School is intended to be viewed by
                        residents of Australia. In the event of any dispute arising out of or in relation to
                        the Website, you agree that the exclusive venue for resolving any dispute shall
                        be in the courts of New South Wales, Australia.</p>
                        <b>Governing Law</b>
                        <p>(a) The Terms are governed by the laws of New South Wales, Australia. Any dispute,
                        controversy, proceeding or claim of whatever nature arising out of or in any way
                        relating to the Terms and the rights created hereby shall be governed, interpreted
                        and construed by, under and pursuant to the laws of New South Wales, Australia,
                        without reference to conflict of law principles, notwithstanding mandatory rules.
                        The validity of this governing law clause is not contested. The Terms shall be
                        binding to the benefit of the parties hereto and their successors and assigns.</p>
                        <b>Independent Legal Advice</b>
                        <p>(a) Both parties confirm and declare that the provisions of the Terms are fair and
                        reasonable and both parties having taken the opportunity to obtain independent
                        legal advice and declare the Terms are not against public policy on the grounds
                        of inequality or bargaining power or general grounds of restraint of trade.</p>
                        <b>Severance</b>
                        <p>(a) If any part of these Terms is found to be void or unenforceable by a Court of
                        competent jurisdiction, that part shall be severed and the rest of the Terms
                        shall remain in force.</p>

            </template>
        </card>
    </div>
</template>

<script>
import Card from "@/components/Section/Card.vue"

export default {
    data() {
        return {
            Title: "NIMCAT ONLINE SCHOOL TERMS AND CONDITIONS OF USE"
        }
    },

    components: {
        Card
    }
}
</script>

<style scoped>
.terms {
    text-align: left;
}

li {
    text-indent: 50px;
}

ol > ol > li {
    text-indent: 80px;
}
.subtitle {
    text-indent: 30px;
}
</style>