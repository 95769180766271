<template>
    <router-view />
</template>


<script>

  export default {
      name: "App",

      provide() {
        return {
            changeLang: this.changeLanguage,
        };
    },

    methods: {
      changeLanguage(lang) {
        this.$i18n.locale = lang;
      }
    },

    created() {
      this.$store.dispatch('login')
      this.$store.dispatch('siteData')
    }
  };
</script>

<style>
#app {
  font-family: IRANSans, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
