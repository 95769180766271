<template>
    <section class="py-3 bgcolor overflow-hidden">
        <slot name="title">
        <h3 class="text-3xl mb-2 font-semibold leading-normal" style="text-align: center;">
             {{ title }}
        </h3>
        </slot>
        <div class="flex flex-wrap px-3">
            <slot name="main"></slot>
        </div>
    </section>
    
</template>

<script>
export default {
    data() {
        
    },

    props: ['title']
}
</script>