import { apiService } from '../api.service.js';


export default {

    siteData(context) {
    let endpoint = "api/site-data";
    apiService(endpoint)
        .then((data) => {
            let response = []
            response = JSON.parse(JSON.stringify(data))[0];
            if (response != undefined) {
                context.commit('setData', {
                    id: response.id,
                    about_us_text: response.about_us_text,
                    about_us_text_f: response.about_us_text_f,
                    first_page_text: response.first_page_text,
                    first_page_text_f: response.first_page_text_f,
                    banner_url: response.banner_url,
                })
            }
            else {
                console.log(data.detail)
            }
        })
        .catch((e) => {
            console.log(e)
        });
    }
}