<template>
  <div>
    <index-navbar />
    <div class="container mt-24">
      <div id="user-profile-2" class="user-profile">
    <div class="tabbable">
      <ul class="nav nav-tabs padding-18">
        <li :class="activeTab=='#home' ? 'active' : ''">
          <a href="#" @click="tabChange('#home')">
            <i class="green ace-icon fa fa-user bigger-120"></i>
            {{ $t("message.profile") }}
          </a>
        </li>

        <li :class="activeTab=='#uclass' ? 'active' : ''">
          <a href="#" @click="tabChange('#uclass')">
            <i class="orange ace-icon fa fa-book bigger-120"></i>
            {{ $t("message.upcommingSessions") }}
          </a>
        </li>
        <li :class="activeTab=='#pclass' ? 'active' : ''">
          <a href="#" @click="tabChange('#pclass')">
            <i class="blue ace-icon fa fa-bookmark bigger-120"></i>
            {{ $t("message.finishedSessions") }}
          </a>
        </li>

        <li v-if="userIsTeacher" :class="activeTab=='#planning' ? 'active' : ''">
          <a href="#" @click="tabChange('#planning')">
            <i class="blue ace-icon fa fa-bookmark bigger-120"></i>
            {{ $t("message.planning") }}
          </a>
        </li>
      </ul>
      <div class="tab-content no-border padding-24">
          <component :is="activeComponent" v-bind="activeProps"></component>
        
      </div>
    </div>
  </div>
    </div>
    <footer-component />
  </div>
</template>
<script>

import FooterComponent from "@/components/Footers/FooterAdmin.vue";
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import UserInfo from "@/views/profile/UserInfo.vue";
import UpcommingClasses from "@/views/profile/UpcommingClasses.vue"
import PassedClasses from "@/views/profile/PassedClasses.vue"
import TeacherPlanning from "@/views/profile/TeacherPlanning.vue"
import { apiService } from "@/common/api.service.js"


export default {
  data() {
    return {
        userData: {},
        userIsTeacher: null,
        activeTab: "#home",
        activeComponent: "user-info",
        upcommingSessions: [],
        passedSessions: []
    };
  },

  methods: {
      tabChange(t) {
          if (t == "#home") {
              this.activeTab = "#home" 
              this.activeComponent = "user-info"
          }
          else if (t == "#uclass") {
              this.activeTab = "#uclass"
              this.activeComponent = "upcomming-classes"
          }
          else if (t == "#pclass") {
              this.activeTab = "#pclass"
              this.activeComponent = "passed-classes"
          }
          else if (t == "#planning" ){
            this.activeTab = "#planning"
            this.activeComponent = "teacher-planning"
          }
      },

      getUserData() {
        let endpoint = "api/user/";
        apiService(endpoint)
            .then((data) => {
                this.userData = JSON.parse(JSON.stringify(data[0]));
                this.userIsTeacher = this.userData.is_teacher
                this.getProfileData();
                this.getSessions()
            })
            .catch((e) => {
                console.log(e);
            });

      },

      getProfileData() {
        let endpoint = "api/student-profile/";
        if (this.userIsTeacher) {
          endpoint = "api/teacher-profile/"
        }
        apiService(endpoint)
            .then((data) => {
              this.userData.profile = JSON.parse(JSON.stringify(data[0]));
            })
            .catch((e) => {
                console.log(e);
            });
        },

      getSessions() {
        let endpoint_u = "api/student-slots/upcomming/";
        let endpoint_p = "api/student-slots/passed/";
        if (this.userIsTeacher) {
          endpoint_u = "api/t-slots/upcomming/";
          endpoint_p = "/api/t-slots/passed/";
        }
        console.log(endpoint_u)
        apiService(endpoint_u)
                .then((data) => {
                    this.upcommingSessions = JSON.parse(JSON.stringify(data));
                })
                .catch((e) => {
                    console.log(e);
                });
        apiService(endpoint_p)
                .then((data) => {
                  this.passedSessions = JSON.parse(JSON.stringify(data));
                })
                .catch((e) => {
                    console.log(e);
                });
      }
  },
  computed: {
      activeProps: function () {
          if (this.activeComponent == "upcomming-classes"){
            return { upcommings: this.upcommingSessions };
          } else if  (this.activeComponent == "passed-classes") {
            return { passed: this.passedSessions }
          } 
          return { userData: this.userData };
      },



  },

  components: {
    IndexNavbar,
    FooterComponent,
    UserInfo,
    UpcommingClasses,
    PassedClasses,
    TeacherPlanning
  },

  created() {
    this.getUserData();
  }
};
</script>



<style>
@import '../assets/styles/profile.css' 
</style>
