<template>
    <div class="how">
        <ol style="list-style-type:disc">
            <li>لطفا جهت استفاده از وب سایت، روی گزینه ثبت نام در بالای صفحه کلیک نمایید</li>
            <li>با وارد نمودن اطلاعات مورد نیاز و تایید Training Service Agreement یک حساب کاربری ایجاد نمایید.
            </li>
            <!-- <li>
                وارد ایمیل خود شوید و مطابق ایمیل ارسالی از طرف ما حساب کاربری خود را فعال نمایید (توجه کنید ممکن است ایمیل ارسالی در قسمت اسپم ایمیل شما قرار گرفته باشد)
            </li>
            <li>
                پس از دریافت پیام موفقیت فعال سازی، با نام کاربری و رمز عبور ایجاد شده در سایت وارد شود.
            </li> -->
            <li>
                وارد قسمت دوره ها شوید، کلاس و آموزگار مورد نظر خود را انتخاب نمایید.
            </li>
            <li>
                در صفحه آموزگار مورد نظرتان، روی گزینه درخواست جلسه معارفه رایگان کلیک کرده و بر اساس جدول زمان بندی، تاریخ و ساعت مناسبتان را انتخاب نمایید تا جلسه رایگان را رزرو نمایید
            </li>
            <li>
                پس از برگزاری جلسه معارفه، در صورت تمایل به ادامه و شروع کلاس ها، در صفحه آموزگار مربوطه، روی گزینه رزرو و پرداخت کلیک نموده و تاریخ و ساعت مناسبتان را انتخاب نمایید. سپس در صفحه پرداخت، هزینه کلاس ها را به صورت تک جلسه یا چند جلسه ای پرداخت نمایید. تاییده رزرو و پرداخت به شما ارسال میگردد.
            </li>
        </ol>
        <p>
            **لینک ورود به کلاس از طریق حساب کاربری، در قسمت کلاس های آتی در اختیار شما قرار می گیرد.
        </p>

        <p>
            <b>
                ویدیوهای آموزش ثبت نام در کانال یوتیوب ما:
            </b>
        </p>

        <ol style="list-style-type:square">
            <li>
                <a href="https://youtu.be/DGIu3R-YgD8" target="_blank">ویدیو آموزش نحوه ثبت نام در وبسایت و ایجاد حساب کاربری</a>
            </li>
            <li>
                <a href="https://youtu.be/7LqsoGZ-wHs" target="_blank">ویدیو آموزش نحوه ثبت نام در دوره</a>
            </li>

        </ol>
    </div>


</template>

<script>
</script>
<style scoped>
.how {
    direction: rtl;
    padding-right: 4em;
    text-align: right;
}
</style>