<template>
    <div v-if="userData != {}">
        <div class="row">
            <div class="col-xs-12 col-sm-3 center">
                <span class="profile-picture">
                    <img
                        class="editable img-responsive"
                        alt=" Avatar"
                        id="avatar2"
                        :src="photoSrc"
                    />
                </span>

                <div class="space space-4"></div>
            </div>
            <!-- /.col -->

            <div class="col-xs-12 col-sm-9">
                <h4 class="blue">
                    <span class="middle">{{ fullName() }}</span>
                </h4>

                <div class="profile-user-info">
                    <div class="profile-info-row">
                        <div class="profile-info-name">
                            {{ $t("message.Username") }}
                        </div>

                        <div class="profile-info-value">
                            <span>{{ userData.username }}</span>
                        </div>
                    </div>

                    <div class="profile-info-row">
                        <div class="profile-info-name">
                            {{ $t("message.Address") }}
                        </div>

                        <div class="profile-info-value">
                            <i
                                class="fa fa-map-marker light-orange bigger-110"
                            ></i>
                            <span>{{ userData.address }}</span>
                        </div>
                    </div>

                    <div class="profile-info-row">
                        <div class="profile-info-name">
                            {{ $t("message.dateofBirth") }}
                        </div>

                        <div class="profile-info-value">
                            <span>{{ userData.date_of_birth }}</span>
                        </div>
                    </div>

                    <div class="profile-info-row">
                        <div class="profile-info-name">
                            {{ $t("message.dateJoint") }}
                        </div>

                        <div class="profile-info-value">
                            <span>{{ getDate(userData.date_joined) }}</span>
                        </div>
                    </div>

                    <div class="profile-info-row">
                        <div class="profile-info-name">
                            {{ $t("message.lastOnline") }}
                        </div>

                        <div class="profile-info-value">
                            <span>{{ getDate(userData.last_login) }}</span>
                        </div>
                    </div>
                </div>

                <div class="hr hr-8 dotted"></div>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->

        <div class="space-20"></div>
    </div>
    <!-- /#home -->
</template>

<script>
import unknownPerson from "@/assets/img/925px-Unknown_person.jpg";

export default {
    data() {
        return {

        };
    },

    props: ['userData'],

    methods: {
        
        fullName() {
            if (this.userData != undefined) {
                if (this.$i18n.locale == "fa") {
                    return (
                        this.userData.first_name_f +
                        " " +
                        this.userData.last_name_f
                    );
                } else if (this.$i18n.locale == "en") {
                    return (
                        this.userData.first_name +
                        " " +
                        this.userData.last_name
                    );
                }
            } else {
                return "Unknown";
            }
        },

        getDate(d) {
            if (d != undefined) {
                let sdate = d.toLocaleString()
                return this.$moment(Date.parse(sdate)).format(
                    "YYYY-MMM-DD HH:mm"
               );
            } else {
                return "-"
            }
            
        },
    },

    computed: {
        photoSrc() {
            if (this.userData.profile!= undefined) {
                return this.userData.profile.profile_url;
            } else {
                return unknownPerson;
            }
        },
    },

};
</script>