<template>
    <div class="courses">
        <div class="mt-2">
            <div class="flex flex-wrap mx-auto px-4">
                <div class="md:w-8/12 px-4">
                    <div class="container">
                        <h5 class="badge badge-info">Calendar Time is based on: {{ LocalTimeZone }} </h5>
                    </div>
                    <full-calendar :options="calendarOptions" />
                </div>
                <select-teacher-sessions
                    id="items-table"
                    :selected_slots="selected_slots"
                    :selected_plan="selected_plan"
                    @remove="removeSlot"
                    @submitClick ="submitClick"
                ></select-teacher-sessions>

                <base-dialog v-if="FreeSessionDialog" title="Success">
                    <template #default>
                        <p>
                            {{ $t("message.SessionSuccess") }}
                        </p>
                        <p>
                            {{ this.selected_slots[0].title}} - {{ this.selected_slots[0].date}}
                        </p>
                    </template>
                    <template #actions>
                        <button @click="closeDialog" class="btn btn-info">
                            {{ $t("message.confirm") }}
                        </button>
                    </template> 
                </base-dialog>

                <base-dialog v-if="PurchasePreview" :title="$t('message.PurchaseDetails')">
                <template #default>
                <table class="table table-bordered">
                    <tr>
                    <th>{{ $t("message.SelectedPlanSessions") }}</th>
                    <td>{{ selected_plan.num_of_sessions }}</td>
                    </tr>
                    <tr>
                    <th>{{ $t("message.TotalPrice") }}</th>
                    <td>{{ TotalPrice }} <span class="badge badge-success">USD</span></td>
                    </tr>
                    <tr>
                    <th>{{ $t("message.SelectedPlanDiscount") }}</th>
                    <td>{{ selected_plan.discount }} <span class="badge badge-success">USD</span></td>
                    </tr>
                    <tr>
                    <th><label for="coupon">{{ $t("message.couponCode") }}</label> 
                    </th>
                    <td><input class="form-control" v-model="couponCode" type="text" name="coupon"> <br> 
                    <button class="btn btn-sm btn-primary" @click="checkCoupon">
                        {{ $t("message.applyCoupon")}} </button></td>
                    </tr>
                    <tr>
                    <th>{{ $t("message.CouponDiscountAmount") }}</th>
                    <td>{{ couponDiscount }} %</td>
                    </tr>
                    
                </table>
                </template>
                <template #actions>
                <button  @click='goToPay' 
                    class="btn btn-sm btn-info">{{ $t('message.goToPayment') }}</button>
                <button @click="cancelSelected" class="btn btn-danger btn-success">{{ $t('message.Cancel') }}</button>
                </template>
            </base-dialog>
                
            </div>
        </div>
    </div>
</template>

<script>
import SelectTeacherSessions from "@/views/Sessions/SelectTeacherSessions.vue";
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import { apiService, patchAxios, postAxios } from "@/common/api.service";
import BaseDialog from "@/components/Modal/BaseDialog.vue";
export default {
    data() {
        return {
            slots: [],
            selected_slots: [],
            LocalTimeZone: "",
            FreeSessionDialog: false,
            PurchasePreview: false,
            couponCode: "",
            couponDiscount: 0,
            calendarOptions: {
                plugins: [timeGridPlugin, dayGridPlugin, momentTimezonePlugin],
                initialView: "timeGridWeek",
                nowIndicator: true,
                height: 500,
                eventClick: this.handleEventClick,
                eventMouseEnter: this.eventMouseEntered,
                events: [],
                firstDay: 6,
                timeZone: 'local',
            },
        };
    },

    props: ["selected_plan"],

    methods: {
        getTeacherSlots() {
            let endpoint =
                "api/svt-slots-view/" + this.$route.params.TeacherId + "/";
            apiService(endpoint)
                .then((data) => {
                    this.slots = JSON.parse(JSON.stringify(data));
                    console.log(this.slots)
                    for (let i = 0; i < this.slots.length; i++) {
                        let midvar = {};
                        midvar.id = this.slots[i]["id"];
                        midvar.title =
                            this.slots[i]["teacher"]["course"]["course_name"];
                        midvar.date = this.slots[i]["start_time"];
                        midvar.duration = "01:00";
                        midvar.is_sold = this.slots[i]["is_sold"];
                        midvar.is_holding = this.slots[i]["is_holding"];
                        midvar.slot_ref_code = this.slots[i]["slot_ref_code"];
                        if (midvar.is_sold) {
                            midvar.backgroundColor = "gray";
                            midvar.title += " (Sold)";
                            midvar.className = "sold-event";
                        } else if (midvar.is_holding) {
                            midvar.backgroundColor = "yellow";
                            midvar.title += " (Holding)";
                            midvar.className = "waiting-event";
                        } else {
                            midvar.className = "availble-event";
                        }
                        this.calendarOptions.events = [
                            ...this.calendarOptions.events,
                            midvar,
                        ];
                        console.log(this.calendarOptions.events)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        getPlanPrice() {
           this.TotalPrice = this.selected_slots[0]["teacher"]["course"]["base_price"]*this.selected_plan.num_of_sessions - this.selected_plan.discount
                
        },

        handleEventClick: function (arg) {
            console.log(this.calendarOptions.events)
            let event = this.calendarOptions.events.find(
                (e) => e.id == arg.event.id
            );
            const indx = this.selected_slots.indexOf(event);
            if (
                this.selected_slots.length >= this.selected_plan.num_of_sessions
            ) {
                return;
            }

            if (!event.is_sold && !event.is_holding && indx <= -1) {
                event.backgroundColor = "green";
                this.selected_slots = [...this.selected_slots, event];
                console.log(this.selected_slots);
            }
        },

        removeSlot(slot) {
            const indx = this.selected_slots.indexOf(slot);
            let event = this.calendarOptions.events.find(
                (e) => e.id == slot.id
            );
            if (indx > -1) {
                this.selected_slots.splice(indx, 1);
                event.backgroundColor = "#007bff";
            }
        },

        submitClick() {
            if (this.selected_slots != []) {
                document.body.style.cursor = 'wait'
                if (this.selected_plan.id == 0) {
                    let endpoint = "api/reserve-free-session/";
                    let data = {
                        teacher_slot: this.selected_slots[0].id,
                        is_free: true,
                    };
                    console.log(data);
                    postAxios(endpoint, data)
                        .then((response) => {
                            console.log(response)
                            this.FreeSessionDialog = true;
                            document.body.style.cursor = 'default'
                        })
                        .catch((e) => {
                            console.log(e);
                            document.body.style.cursor = 'default'
                        });
                } else {
                    this.PurchasePreview = true
                    document.body.style.cursor = 'default'
                }
            }
        },

        cancelSelected() {
          this.PurchasePreview = false;
          this.couponCode = "";
          this.couponDiscount = 0
      },
        
        goToPay() {
            let data = [];
            let endpoint = "api/svt-slots-buy/";
            this.selected_slots.forEach((element) => {
                data.push({
                    slot_ref_code: element.slot_ref_code,
                    is_holding: true,
                    selected_plan: this.selected_plan.id,
                    coupon_code: this.couponCode
                });
            });
            console.log(data);
            patchAxios(endpoint, data)
                .then((data) => {
                    let ref_code = data.data[data.data.length - 1];
                    console.log(ref_code.bill_ref_code);
                    window.location.href =
                        "payment/pay-bill/" + ref_code.bill_ref_code;
                })
                .catch((e) => {
                    console.log(e);
                });
            },

        checkCoupon() {
            let endpoint = "api/check-coupon/" + this.couponCode + "/"
            apiService(endpoint).then((data) => {
            let discount = data['discount_percent']
            if (discount != undefined) {
                this.couponDiscount = discount
            }
            }).catch((e)=>{
            console.log(e)
            })
        },

        closeDialog() {
            this.FreeSessionDialog = false;
            window.location.href = "profile";
        },
    },

    computed: {
        TotalPrice() {
            if (this.selected_slots.length > 0) {
                return this.slots[0]["teacher"]["course"]["base_price"]*this.selected_plan.num_of_sessions - this.selected_plan.discount 
            }
            return 0
        }
    },

    components: {
        SelectTeacherSessions,
        FullCalendar,
        BaseDialog,
    },

    beforeCreate() {
        this.LocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    },

    created() {
        this.getTeacherSlots();
        this.LocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        
    },
};
</script>
