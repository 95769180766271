<template>
    <div class="planning">
        <div class="mt-2">
            <div>
                <button
                    v-if="!addEventMode"
                    class="btn btn-info"
                    @click="startAdding"
                >
                    {{ $t("message.StartAddingSessions") }}
                </button>
                <button
                    v-if="addEventMode"
                    class="btn btn-success"
                    @click="submitNewEvents"
                >
                    {{ $t("message.Submit") }}
                </button>
                <div id="alert-area" style="position:absolute;" v-if="alertContent != ''"
                class="alert" :class="alertClass" role="alert">
                {{ alertContent }}
                </div>
                <div class="container">
                        <h5 class="badge badge-info">Calendar Time is based on: {{ LocalTimeZone }} </h5>
                    </div>
                <full-calendar :options="calendarOptions" />
            </div>
        </div>
    </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { apiService, postAxios } from "@/common/api.service";
import momentTimezonePlugin from '@fullcalendar/moment-timezone'

export default {
    data() {
        return {
            LocalTimeZone: "",
            calendarOptions: {
                timeZone: "local",
                nowIndicator: true,
                dateFormat: "YYYY-MMM-DD HH:mm",
                plugins: [
                    timeGridPlugin,
                    dayGridPlugin,
                    interactionPlugin,
                    momentTimezonePlugin
                ],
                eventOverlap: false,
                selectOverlap: false,
                slotEventOverlap: false,
                firstDay: 6,
                validRange: function (nowDate) {
                    return {
                        start: nowDate,
                    };
                },
                dateClick: this.createEvent,
                initialView: "timeGridWeek",
                height: 400,
                eventClick: this.handleEventClick,
                eventMouseEnter: this.eventMouseEntered,
                theme: true,
                events: [],
                editable: false,
                eventDrop: this.handleEventDrag,
                eventDurationEditable: false,
            },
            slots: [],
            alertContent: "",
            alertClass: "",
            addEventMode: false,

        };
    },

    props: ["userData"],

    methods: {
        
        newId() {
            return 'new' + (new Date()).getTime()
        },

        startAdding: function () {
            if (!this.addEventMode) {
                this.addEventMode = true;
                this.calendarOptions.editable = true
            }
        },

        getPreviouslyCreatedEvents() {
            let endpoint = "api/t-slots/upcomming/";

            apiService(endpoint)
                .then((data) => {
                    this.slots = JSON.parse(JSON.stringify(data));
                    for (let i = 0; i < this.slots.length; i++) {
                        let midvar = {};
                        midvar.id = this.slots[i]["id"];
                        midvar.title =
                            this.slots[i]["teacher"]["course"]["course_name"];
                        midvar.date = this.slots[i]["start_time"];
                        midvar.duration = "01:00";
                        midvar.is_sold = this.slots[i]["is_sold"];
                        midvar.is_holding = this.slots[i]["is_holding"];
                        midvar.slot_ref_code = this.slots[i]["slot_ref_code"];
                        midvar.editable = false;
                        if (midvar.is_sold) {
                            midvar.backgroundColor = "gray";
                            midvar.title += " (Sold)";
                            midvar.className = "sold-event";
                        } else if (midvar.is_holding) {
                            console.log(midvar);
                            midvar.backgroundColor = "yellow";
                            midvar.title += " (Holding)";
                            midvar.className = "waiting-event";
                        } else {
                            midvar.className = "availble-event";
                        }
                        this.calendarOptions.events = [
                            ...this.calendarOptions.events,
                            midvar,
                        ];
                        console.log(this.calendarOptions.events);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        createEvent(info) {
            if (this.addEventMode) {
                this.calendarOptions.events.push({
                    id: this.newId(),
                    groupId: "new",
                    title: this.userData.last_name,
                    start: info.dateStr,
                });
            }
        },

        submitNewEvents() {
            let endpoint = "api/t-create-slots/";
            console.log(this.calendarOptions.events )
            if (this.calendarOptions.events.length > 0) {
                let data = [];
                this.calendarOptions.events.forEach((ev) => {
                    ev.resourceEditable = false
                    if (ev.groupId == "new") {
                        let tempEvent = {
                            start_time: ev.start,
                            teacher: this.userData.profile.id,
                        };
                        data.push(tempEvent);
                    }
                });
                postAxios(endpoint, data)
                    .then((response) => {
                        this.addEventMode = false;
                        this.calendarOptions.editable = false
                        this.alertClass = "alert-success"
                        this.alertContent = "Submit successfully!"
                        setTimeout(() => this.alertContent = "", 3000);
                        console.log(response)
                        
                    })
                    .catch((e) => {
                        this.addEventMode = false;
                        this.alertClass = "alert-danger"
                        this.alertContent = "Could not submit slots!"
                        setTimeout(() => this.alertContent = "", 3000);
                        console.log(e);
                    });
            } else {
                console.log("empty new sessions");
            }
        },

        handleEventDrag(info) {
            
            let event = this.calendarOptions.events.find((e) => e.id == info.event.id)
            event.start = info.event.start
        }
    },

    components: {
        FullCalendar,
    },

    created() {
        this.getPreviouslyCreatedEvents();
        this.LocalTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    },
};
</script>

<style>
.fc .fc-toolbar-title {
    font-size: 1.2em !important;
}

.fc .fc-button {
    font-size: 0.8em !important;
}
</style>