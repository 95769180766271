<template>
    <div class="courses">
     <index-navbar />
     
     <div class="flex flex-wrap px-3 mt-24" >
         <div>
         </div>

            <div v-for="book in Books" :key="book.id" class="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div class="px-4 py-5 flex-auto">
                  <div
                    class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400"
                  >
                    <img
                        alt=".."
                        :src="book.file_image_url"
                        class="
                            shadow-lg
                            rounded-full
                            mx-auto
                            max-w-120-px
                        "
                    />
                  </div>
                  <h6 class="text-xl font-semibold" v-html="book.file_name" ></h6>
                  <p class="mt-2 mb-4 text-blueGray-500" v-html="book.file_description">
                  </p>
                </div>
                <button v-if="book.is_sold"
                  @click="downloadFile(book)"
                  class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button">
                    <i class="fas fa-download"></i> {{ $t("message.Download") }}
                </button>
                <button v-else
                  @click="buyBook(book)"
                  class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button">
                    <i class="fas fa-dollar"></i>  {{ book.price}}
                </button>
              </div>
            </div>

          </div>
          <base-dialog v-if="itemSelected != null" :title="$t('message.PurchaseDetails')">
            <template #default>
              <table class="table table-bordered">
                <tr>
                  <th>{{ $t("message.item") }}:</th>
                  <td>{{ itemSelected.file_name }}</td>
                </tr>
                <tr>
                  <th>{{ $t("message.price") }}:</th>
                  <td>{{ itemSelected.price }} <span class="badge badge-success">USD</span></td>
                </tr>
                <tr>
                  <th>{{ $t("message.payable") }}:</th>
                  <td>{{ itemSelected.price - discount*itemSelected.price/100 }} 
                    <span class="badge badge-success">USD</span></td>
                </tr>
                <tr>
                  <th>{{ $t("message.DiscountAmount") }}:</th>
                  <td>{{ discount }} %</td>
                </tr>
                <tr>
                  <th><label for="coupon">{{ $t("message.couponCode") }}:</label> 
                  </th>
                  <td><input class="form-control" v-model="couponCode" type="text" name="coupon"> <br> 
                  <button class="btn btn-sm btn-primary" @click="checkCoupon">
                    {{ $t("message.applyCoupon")}} </button></td>
                </tr>
              </table>
            </template>
            <template #actions>
              <button  @click='goToPay' 
                class="btn btn-sm btn-info">{{ $t('message.goToPayment') }}</button>
              <button @click="cancelSelected" class="btn btn-danger btn-success">{{ $t('message.Cancel') }}</button>
            </template>
          </base-dialog>

          <base-dialog v-if="anonymousModal" :title="$t('message.login')">
            <template #default>
                <p>{{ $t("message.loginFirst") }}</p>
            </template>
            <template #actions>
                <button class="btn btn-sm btn-info"
                onclick="location.href='/accounts/login/'">
                 {{ $t("message.login") }}
                </button>
                <button class="btn btn-sm btn-danger" @click="close()">
                {{ $t("message.close") }}</button>
            </template>
        </base-dialog>
    <footer-component />
  </div>
</template>


<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/FooterAdmin.vue";
import { apiService, postAxios } from "@/common/api.service";
import BaseDialog from "@/components/Modal/BaseDialog.vue";

export default {
  name: "Books",

  data() {
      return {
          Books: [],
          itemSelected: null,
          discount: 0,
          couponCode: "",
          anonymousModal: false,
      };
  },

  methods: {
      getBookList() {
          let endpoint = "api/shop/";
          apiService(endpoint)
              .then((data) => {
                  const midvar = [];
                  midvar.push(...data);
                  this.Books = JSON.parse(JSON.stringify(midvar));
              })
              .catch((e) => {
                  console.log(e);
              });
      },

      buyBook(book) {
        if (! this.$store.state.auth.userIsAuth) { 
          this.anonymousModal = true
        }
        else{
          this.itemSelected = book;
        }
      },

      close() {
            this.anonymousModal = false
      },

      checkCoupon() {
        let endpoint = "api/check-coupon/" + this.couponCode + "/"
        apiService(endpoint).then((data) => {
          let discount = data['discount_percent']
          if (discount != undefined) {
            this.discount = discount
          }
        }).catch((e)=>{
          console.log(e)
        })
      },

      downloadFile(book) {
        window.location.href="payment/download-book/" + book.id;
      },

      goToPay() {
        let endpoint = "api/buy-file/"
        if ( this.itemSelected != null ){
          let data = {
            book_id:this.itemSelected.id,
            book_name: this.itemSelected.file_name,
            coupon_code: this.couponCode,
          }
          postAxios(endpoint, data).then((response)=>{
            if (response.data.bill_ref_code != '') {
              window.location.href = "payment/pay-bill/" + response.data.bill_ref_code;
            }
          }).catch((e)=>{
            console.log(e)
          })
        }
      },

      cancelSelected() {
          this.itemSelected = null;
          this.discount = 0;
          this.couponCode = "";
      },
  },

  components: {
      IndexNavbar,
      FooterComponent,
      BaseDialog,
  },

  created() {
      this.getBookList();
  },
};
</script>