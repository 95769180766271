<template>
    <div id="uclass">
        <div v-if="upcommings.length > 0">
            <!-- For Teacher -->
            <data-table class="table table-striped nowrap"
            v-if="$store.state.auth.userIsTeacher" 
            :title="$t('message.upcommingSessions')">
                <template #thead>
                    <th>{{ $t("message.row") }}</th>
                    <th>{{ $t("message.CourseName") }}</th>
                    <th>{{ $t("message.StartTime") }}</th>
                    <th >{{ $t("message.Booked") }}</th>
                    <th>{{ $t("message.EnterClass") }}</th>
                    <th >{{ $t("message.DeleteSession") }}</th>
                </template>
                <template #tbody>
                    <tr v-for="(us, index) in upcommings" :key="us.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ us.teacher.course.course_name }}</td>
                        <td>{{ formatDate(us.start_time) }}</td>
                        <td><i :class="iconTrueFalse(us.is_sold)"></i></td>
                        <td><a v-if="us.meeting_url != ''" :href="us.meeting_url" class="btn btn-info">{{ $t("message.Enter") }}</a></td>
                        <td><button v-if="! us.is_sold" class="btn btn-sm btn-danger" 
                        @click="openDeleteDialog(us.id)">
                            {{ $t("message.Remove") }}
                        </button></td>
                    </tr>
                </template>
            </data-table>
            <!-- End of Teacher -->

            <!-- For Student -->
            <data-table class="table table-striped nowrap"
            v-else 
            :title="$t('message.upcommingSessions')">
                <template #thead>
                    <th>{{ $t("message.row") }}</th>
                    <th>{{ $t("message.CourseName") }}</th>
                    <th>{{ $t("message.StartTime") }}</th>
                    <th>{{ $t("message.EnterClass") }}</th>
                </template>
                <template #tbody>
                    <tr v-for="(us, index) in upcommings" :key="us.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ us.teacher_slot.teacher.course.course_name }}</td>
                        <td>{{ formatDate(us.teacher_slot.start_time) }}</td>
                        <td><a v-if="us.meeting_url != ''" :href="us.meeting_url" class="btn btn-sm btn-info">{{ $t("message.Enter") }}</a></td>
                    </tr>
                </template>
            </data-table>
            <!-- End of Student -->
        </div>
        <div v-else>
            <h4> {{ $t("message.noUpcommings") }}</h4>
        </div>
        <!-- /.row -->
        <base-dialog v-if="teacherDeleteSlot" :title='$t("message.DeleteSession")'>
            <template #default>
                <p v-html="$t('message.AreYouSure')"></p>
            </template>
            <template #actions>
                <div class="btn-group">
                    <button  @click='removeClass' 
                    class="btn btn-sm btn-danger">{{ $t('message.Remove') }}
                    </button>
                    <button @click="closeDialog" class="btn btn-sm btn-success">
                        {{ $t('message.close') }}</button>
                </div>
            </template>
        </base-dialog>

        <div class="space-12"></div>
    </div>
</template>

<script>
import DataTable from "@/components/Tables/DataTable.vue"
import BaseDialog from "@/components/Modal/BaseDialog.vue"
import { deleteAxios } from "@/common/api.service.js"
import { iconTrueFalse, formatDate } from "@/common/functions"
export default {
    data() {
        return {
            teacherDeleteSlot: false,
            selectedDelete: '',
        }
    },

    props: ["upcommings"],


    methods: {
        iconTrueFalse,
        formatDate,

        openDeleteDialog(sid) {
            this.teacherDeleteSlot =true
            this.selectedDelete = sid
        },

        closeDialog() {
            this.teacherDeleteSlot =false
            this.selectedDelete = ''

        },


        removeClass() {
            if (this.selectedDelete != '') {
                let endpoint = "api/t-delete-slots/"+ this.selectedDelete + "/"
                let d = {}
                deleteAxios(endpoint, d)
                .then((data) => {
                    this.teacherDeleteSlot = false
                    this.selectedDelete = ''
                    this.teacher = ''
                    console.log(data)
                    location.reload();
                }).catch((e) => {
                    console.log(e)
                })
            }

        }
    },

    components: {
        DataTable,
        BaseDialog
    }
};
</script>