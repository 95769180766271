<template>
<div>
    <page-section :title='$t("message.Services")'>
        <template #main>
            
            <div class="pt-6 w-full md:w-3/12 px-4 text-center">
                <div
                    class="
                        relative
                        flex flex-col
                        min-w-0
                        break-words
                        bg-trans-blue
                        w-full
                        mb-8
                        shadow-lg
                        rounded-lg
                    "
                >
                  <a href="/shop">
                    <div class="px-4 py-5 text-inside maxh-300">
                        <div
                            class="
                                text-white
                                p-3
                                text-center
                                inline-flex
                                items-center
                                justify-center
                                w-12
                                h-12
                                mb-5
                                shadow-lg
                                rounded-full
                                bg-nimcat-blue
                            "
                        >
                            <i class="fas fa-book"></i>
                        </div>
                            <h6 class="text-xl font-semibold text-white">
                                {{ $i18n.locale == "fa"
                                ? "فروشگاه"
                                : "Shop"}}
                            </h6>
                            <p class="mt-2 mb-4 mr-5d ml-5d text-white">
                            </p>
                    </div>
                  </a>
                </div>
            </div>

            <div class="pt-6 w-full md:w-3/12 px-4 text-center">
                <div
                    class="
                        relative
                        flex flex-col
                        min-w-0
                        break-words
                        bg-trans-blue
                        w-full
                        mb-8
                        shadow-lg
                        rounded-lg
                    "
                >
                    <div class="px-4 py-5 text-inside maxh-300">
                        <div
                            class="
                                text-white
                                p-3
                                text-center
                                inline-flex
                                items-center
                                justify-center
                                w-12
                                h-12
                                mb-5
                                shadow-lg
                                rounded-full
                                bg-nimcat-blue
                            "
                        >
                            <i class="fas fa-user-graduate"></i>
                        </div>
                            <h6 class="text-xl font-semibold text-white"
                            v-html='$t("message.OnlineWorkShops")'>
                            </h6>
                            <p class="mt-2 mb-4 mr-5d ml-5d text-white">
                            </p>
                    </div>
                </div>
            </div>
            
            <div class="pt-6 w-full md:w-3/12 px-4 text-center">
                <div
                    class="
                        relative
                        flex flex-col
                        min-w-0
                        break-words
                        bg-trans-blue
                        w-full
                        mb-8
                        shadow-lg
                        rounded-lg
                    "
                >
                    <div class="px-4 py-5 text-inside maxh-300">
                        <div
                            class="
                                text-white
                                p-3
                                text-center
                                inline-flex
                                items-center
                                justify-center
                                w-12
                                h-12
                                mb-5
                                shadow-lg
                                rounded-full
                                bg-nimcat-blue
                            "
                        >
                            <i class="fas fa-images"></i>
                        </div>
                            <h6 class="text-xl font-semibold text-white"
                            v-html='$t("message.Publications")'>
                            </h6>
                            <p class="mt-2 mb-4 mr-5d ml-5d text-white">
                            </p>
                    </div>
                </div>
            </div>

            <div class="pt-6 w-full md:w-3/12 px-4 text-center">
                <div
                    class="
                        relative
                        flex flex-col
                        min-w-0
                        break-words
                        bg-trans-blue
                        w-full
                        mb-8
                        shadow-lg
                        rounded-lg
                    "
                >
                    <div class="px-4 py-5 text-inside maxh-300">
                        <div
                            class="
                                text-white
                                p-3
                                text-center
                                inline-flex
                                items-center
                                justify-center
                                w-12
                                h-12
                                mb-5
                                shadow-lg
                                rounded-full
                                bg-nimcat-blue
                            "
                        >
                            <i class="fas fa-user-friends"></i>
                        </div>
                            <h6 class="text-xl font-semibold text-white"
                            v-html='$t("message.consultation")'>
                            </h6>
                            <p class="mt-2 mb-4 mr-5d ml-5d text-white">
                            </p>
                    </div>
                </div>
            </div>

        </template>
    </page-section>
</div>
</template>

<script>

import PageSection from '@/components/Section/pageSection.vue';

export default {
    data() {
        return {
        }
        
    },


    components: {
        PageSection
    },

}
</script>

<style scoped>
.grad {
    background-image: linear-gradient(#f7d72a, white);
}

.box-border {
    border: solid;
    border-color: #000080;
}

.text-inside {
    color: #000080;
}

.farsi {
    text-align: right;
    direction: rtl;
}
.nofarsi {
    text-align: left;
    direction: ltr;
}

.bg-trans-blue {
    background-color: rgba(249,186,0,0.5);
}
.bg-trans-blue :hover {
    background-color: rgba(249,186,0,1) !important;
}

.maxh-300 {
    max-height: 300px;
}
</style>