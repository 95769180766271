<template>
  <div class="home">
     <index-navbar/>
    <section
      class="header relative pt-16 items-center flex h-screen max-h-550-px mt-2"
    >
      <div class="container mx-auto items-center flex flex-wrap">
        <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="pt-32 sm:pt-0">
            <h2 class="font-semibold text-4xl text-blueGray-600">
              {{ $t("message.Nimcat") }}
            </h2>
          
            <p v-html="$i18n.locale == 'fa' ? $store.state.SiteData.first_page_text_f : $store.state.SiteData.first_page_text"
             class="mt-4 text-lg leading-relaxed text-blueGray-500">
            </p>
            <div class="mt-12">
              <button
                @click="openAbout"
                class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                {{ $t("message.About") }}
              </button>
              <button
                @click="howRegister"
                class="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                {{ $t("message.HowToRegister") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <img
        class="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860-px responsive"
        :src= banner
        alt="nimcat online school"
      />
    </section>
    <img
        class="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860-px responsive  mid-img"
        src= "/static/img/back_nimcat.jpg"
        alt="nimcat online school"
      />
    <!-- <div class="banner mb-4">
      <div>
        <h1>کارگاه آنلاین شعر و قصه </h1>
        <h5>Saturday Sep 10th, 2022 16:00 Melbourne time</h5>
        <a class="btn btn-lg btn-primary mb-2" target="_blank" href="https://meet.google.com/van-mcvz-pjg">وارد شوید</a>
      </div>  
    </div>  -->
    <courses />
    <services />
    <why-nimcat />
    <top-teachers/>


    <!-- <base-dialog v-if="UserAboutUs" :title="$t('message.About')">
      <template #default>
        <p v-html="$i18n.locale=='fa' ? $store.state.SiteData.about_us_text_f : $store.state.SiteData.about_us_text"></p>
      </template>
      <template #actions>
        <div class="btn-group">
          <button  onclick='location.href="mailto:info@nimcatonlineschool.com"' 
          class="btn btn-sm btn-info">{{ $t('message.sendEmail') }}</button>
          <button @click="closeAbout" class="btn btn-danger btn-success">{{ $t('message.close') }}</button>
        </div>
      </template>
    </base-dialog>  -->

    <base-dialog v-if="HowToReg" :title="$t('message.RegistrationGuid')">
      <template #default>
        <how-register />
      </template>
      <template #actions>
        <div class="btn-group">
          <button @click="closeHowReg" class="btn btn-danger btn-success">{{ $t('message.close') }}</button>
        </div>
      </template>
    </base-dialog> 

    <base-dialog v-if="UserJoin" :title="$t('message.JoinUs')">
      <template #default>
        <join-form @close="closeJoin()" />
      </template>
      <template #actions>
        <div class="btn-group">
          <button @click="closeJoin" class="btn btn-danger btn-success">{{ $t('message.close') }}</button>
        </div>
      </template>
    </base-dialog>

    <base-dialog v-if="contactUs" :title="$t('message.contactUs')">
      <template #default>
        <ul style="direction:ltr;">
          <li>Email: <b class="text-nimcat">admin@nimcatonlineschool.com</b></li>
          <li>Whatsapp:<b class="text-nimcat"> +61481841920</b></li>
        </ul>
      </template>
      <template #actions>
        <div class="btn-group">
          <button @click="closeContacts" class="btn btn-danger btn-success">{{ $t('message.close') }}</button>
        </div>
      </template>
    </base-dialog>
  <footer-component @openContacts="openContactUs" @openJoinUs="openJoin" />
  </div>
</template>

<script>
// @ is an alias to /src
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import TopTeachers from "@/views/teachers/TopTeachers.vue";
import BaseDialog from "@/components/Modal/BaseDialog.vue";
import Courses from './firstPage/Courses.vue';
import Services from './firstPage/Services.vue';
import WhyNimcat from './firstPage/WhyNimcat.vue';
import howRegister from './firstPage/HowRegister.vue';
import JoinForm from './firstPage/JoinForm.vue';
import back from "@/assets/img/back_nimcat.jpg";


export default {
  name: 'Home',

  data() {
    return {
      UserAboutUs: false,
      contactUs: false,
      UserJoin: false,
      HowToReg: false,
      background: back
    }
  },

  methods: {

    openAbout: function() {
      // if (this.UserAboutUs == false) {
      //   this.UserAboutUs = true
      // } else {
      //   this.UserAboutUs = false
      // }
      window.location.href="about-us"
    },


    openJoin: function() {
      console.log("open join us")
      if (this.UserJoin == false) {
        this.UserJoin = true
      } else {
        this.UserJoin = false
      }
    },

    closeAbout: function()  {
      if (this.UserAboutUs == true) {
        this.UserAboutUs = false
      } 
    },

    closeJoin: function()  {
      if (this.UserJoin == true) {
        this.UserJoin = false
      } 
    },

    howRegister: function() {
      this.HowToReg = true
    },

    closeHowReg: function() {
      this.HowToReg = false
    },

    openContactUs: function() {
      this.contactUs = true
    },

    closeContacts: function() {
      this.contactUs = false
    }

    },


  components: {
    IndexNavbar,
    FooterComponent,
    TopTeachers,
    BaseDialog,
    Courses,
    Services,
    WhyNimcat,
    JoinForm,
    howRegister
  },

  computed:{
 
    banner() {
      return this.$store.state.SiteData.banner_url
    }
  }

}
</script>

<style scoped>
.foot {
  margin-top: 30rem;
}

.responsive {
  width: 50%;
  height: auto;
  z-index: -1;
}

.mid-img {
  width: 100% !important;
  margin-top:550px;
}

.banner {
    color: #00235f;
    background: linear-gradient(180deg, #f9ba00, rgb(255, 255, 255, 0.6));
    background-size: 400% 400%;

    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@media screen and (max-width: 768px) {
  .banner {
     margin-top: 120px;
  }
}

@-webkit-keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@-moz-keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}
@keyframes AnimationName {
    0%{background-position:51% 0%}
    50%{background-position:50% 100%}
    100%{background-position:51% 0%}
}

@media (min-width: 768px) {
    dialog {
        left: 5%;
        width: 90%;
        top: 10vh;
    }
    
}

@media (max-width: 768px) {
    .responsive {
        width: 50%;
        height: auto;
    }
    
}
</style>
