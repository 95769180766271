<template>
  <nav 
    :class='navbarColor'
    class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow"
  >
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      
      
      <div
        class="lg:flex flex-grow items-center"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning"
      >
      <ul class="flex flex-col lg:flex-row list-none mr-auto">
        <li class="flex items-center" :class="mtx">
              <a
                href="/helpdesk/"
                class="bg-yellow-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 xs:mb-2 ease-linear transition-all duration-150"
                type="button"
              >
                <i class="fas fa-headphones-alt"></i> {{ $t("message.support") }}
              </a>
          </li>
        <li v-if="$store.state.auth.userIsAuth" class="flex items-center" :class="mtx">
          <a
            href="/accounts/logout/"
            class="bg-red-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-2 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 xs:mb-2 ease-linear transition-all duration-150"
            type="button"
          >
            <i class="fas fa-sign-out-alt"></i>
          </a>
        </li>
          
        <li v-if="$store.state.auth.userIsAuth" class="flex items-center" :class="mtx">
          <a
            href="/profile"
            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 sm:mb-2 ease-linear transition-all duration-150"
            type="button"
          >
            <i class="fas fa-user-alt"></i> {{ $t("message.profile") }}
          </a>
        </li>
            <li v-else class="flex items-center" :class="mtx">
            <a
              href="/accounts/login/"
              class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 sm:mb-2 ml-3 ease-linear transition-all duration-150"
              type="button"
            >
              <i class="fas fa-user-alt"></i> {{ $t("message.login") }}
            </a>
          </li>
          <li class="flex items-center" :class="mtx">
            <a
              href="/shop"
              class="bg-nimcat text-white text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 sm:mb-2 ml-3 ease-linear transition-all duration-150"
              type="button"
            >
              <i class="fas fa-book"></i> {{ $t("message.Shop") }}
            </a>
          </li>
      </ul>
      
      
        <ul class="flex flex-col lg:flex-row list-none ml-auto">


          <li class="flex items-center">
            <button
              class="btn-sm btn-primary rounded shadow hover:shadow-lg text-blueGray-700 mr-2 px-3 py-2 flex items-center text-xs uppercase font-bold"
              onclick="location.href='/teachers-all'"
            >
              {{ $t("message.SeeCourses") }}
            </button>
          </li>
    
          <li v-if="!$store.state.auth.userIsAuth" class="flex items-center">
            <a
              class="hover:text-blueGray-500 text-blueGray-700 px-3 py-2 flex items-center text-xs uppercase font-bold"
              href="/accounts/register/"
              ref="btnDropdownRef"
              v-on:click="toggleDropdown($event)"
            >
              {{ $t("message.register") }}
            </a>
          </li>
          <li v-else class="flex items-center">
            <span v-if="$i18n.locale == 'fa'" class="badge">
               {{ $store.state.auth.userFirstNameF }} {{ $t("message.welcome") }}
            </span>
            <span v-else class="badge">
               {{ $t("message.welcome") }} {{ $store.state.auth.userFirstName }} 
            </span>
          </li>
          <li class="flex items-center">
            <notification-dropdown />
          </li>
        </ul>
      </div>
      <button
        class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
        v-on:click="setNavbarOpen"
      >
        <i class="fas fa-bars"></i>
      </button>
      <router-link to="/">
          <a
            class="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            href="#pablo"
          >
            Nimcat
          </a>
        </router-link>

        
    </div>
  </nav>
</template>

<script>
import NotificationDropdown from "@/components/Dropdowns/NotificationDropdown.vue";

export default {
  data() {
    return {
      navbarOpen: false,
      navbarColor: '',
      scrolled: 0,
      langs: ['fa', 'en'],
      mtx: ''
    };
  },


  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
      this.mtx = "mt-2"
    },

    handleScroll() {
      if (window.scrollY > 100) {
        this.navbarColor = "yellowBg" 
      }
      else {
        this.navbarColor = ''
      }
    }
  },

 
  components: {
    NotificationDropdown
  },

  created() {
    window.addEventListener('scroll', this.handleScroll);
    console.log(this.$store)
  }
};
</script>

<style scoped>
.yellowBg {
  background-color: #FEFFE5;
}

.text-underline-hover {
    text-decoration: none;
}


ul {margin-bottom:0;}
</style>
